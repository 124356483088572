import React, { forwardRef, SVGProps } from 'react';

const Add = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="M8.5 7.5v-5h-1v5h-5v1h5v5h1v-5h5v-1z" clipPath="url(#a)" />
    </svg>
  );
});

const ArrowDown = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="M12.295 8.295 8.5 12.085V2h-1v10.085l-3.795-3.79L3 9l5 5 5-5z" clipPath="url(#a)" />
    </svg>
  );
});

const ArrowLeft = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="m7 13 .705-.705L3.915 8.5H14v-1H3.915l3.79-3.795L7 3 2 8z" clipPath="url(#a)" />
    </svg>
  );
});

const ArrowRight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="m9 3-.715.696 3.79 3.804H2v1h10.075l-3.79 3.787L9 13l5-5z" clipPath="url(#a)" />
    </svg>
  );
});

const ArrowUp = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="M8 2 3 7l.705.705L7.5 3.915V14h1V3.915l3.795 3.79L13 7z" clipPath="url(#a)" />
    </svg>
  );
});

const Bolt = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M5.805 14.96a.5.5 0 0 1-.3-.535l.91-5.925H4a.5.5 0 0 1-.5-.615l1.5-6.5A.5.5 0 0 1 5.5 1h5a.5.5 0 0 1 .49.61l-.865 3.89H12.5a.5.5 0 0 1 .45.28.5.5 0 0 1-.055.5l-6.5 8.5A.5.5 0 0 1 6 15a.6.6 0 0 1-.195-.04m3.07-8.46 1-4.5H5.9L4.63 7.5h2.955l-.795 5.14L11.5 6.5z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Book = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 2.5h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1m5.5 1H2v9h5.5zm1 0v9H14v-9zM6.5 5H3v1h3.5zM13 5H9.5v1H13zm0 2.5H9.5v1H13zM9.5 10H13v1H9.5zM3 7.5h3.5v1H3zM6.5 10H3v1h3.5z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Bookmark = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M12 2v11.375L8.45 11.58 8 11.355l-.45.225L4 13.375V2zm0-1H4a1 1 0 0 0-1 1v13l5-2.5 5 2.5V2a1 1 0 0 0-1-1"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const BracketsCurly = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M5.948 3h-.073a9 9 0 0 0-.88.029c-.348.035-.728.115-1.068.32-.746.446-1.063 1.072-.925 2.452.115 1.14-.117 1.347-.286 1.499l-.021.019C2.51 7.487 2.262 7.5 2 7.5v1c.262 0 .51.014.695.183l.021.019c.17.151.4.358.286 1.498-.137 1.38.18 2.006.925 2.453.34.204.72.284 1.069.319.248.025.505.028.74.028H6v-1h-.136c-.274 0-.527.001-.769-.023-.278-.028-.493-.086-.654-.182l-.034-.02c-.249-.146-.526-.31-.41-1.475.126-1.26-.093-1.82-.57-2.3.477-.479.696-1.04.57-2.3-.116-1.165.161-1.329.41-1.476l.034-.02c.161-.096.376-.153.655-.181.242-.025.495-.024.77-.024H6V3zm4.105 0h.072c.271 0 .582-.001.88.029.347.035.728.115 1.068.32.746.446 1.063 1.072.925 2.452-.114 1.14.117 1.347.286 1.499l.021.019c.185.168.433.182.695.182v1c-.262 0-.51.014-.695.183l-.021.019c-.17.151-.4.358-.287 1.498.139 1.38-.178 2.006-.924 2.453-.34.204-.72.284-1.069.319a8 8 0 0 1-.741.028H10v-1h.136c.274 0 .527.001.769-.023.278-.028.493-.086.654-.182l.034-.02c.249-.146.526-.31.41-1.475-.127-1.26.093-1.82.57-2.3-.477-.479-.697-1.04-.57-2.3.116-1.165-.162-1.329-.41-1.476l-.034-.02c-.161-.096-.376-.153-.655-.181a8 8 0 0 0-.77-.024H10V3z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const BracketsSquare = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3.5 3H3v10h3v-1H4V4h2V3zM10 4h2v8h-2v1h3V3h-3z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Calendar = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M13 2h-2V1h-1v1H6V1H5v1H3c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1m0 11H3V6h10zm0-8H3V3h2v1h1V3h4v1h1V3h2z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const CaretDown = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="m12 6-4 5-4-5z" clipPath="url(#a)" />
    </svg>
  );
});

const CaretUp = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="m4 10 4-5 4 5z" clipPath="url(#a)" />
    </svg>
  );
});

const ChartArea = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10.238 4.008 6.724 2.105a1 1 0 0 0-1.058.103L2 5V1H1v13a1 1 0 0 0 1 1h13V2.868zM14 10.105l-3.81.9-3.514-1.441a1 1 0 0 0-1.011.185L2 12.918V10.69l4.188-4.7L9.697 8.8a1.01 1.01 0 0 0 1.023.106L14 7.301zM6.262 2.991l3.515 1.903c.208.103.444.13.67.08L14 4.132V6.19l-3.69 1.82L6.8 5.2a.995.995 0 0 0-1.344.132L2 9.192V6.257zM2.276 14l4.034-3.506 3.514 1.442a1 1 0 0 0 .574.039L14 11.13V14z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const ChartBar = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 1H1v13a1 1 0 0 0 1 1h13v-1H2v-1.5h11v-4H2v-2h7v-4H2zm10 8.5v2H2v-2zm-4-6v2H2v-2z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const ChartColumn = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M13.5 14V3h-4v11h-2V7h-4v7H2V1H1v13a1 1 0 0 0 1 1h13v-1zm-7 0h-2V8h2zm6 0h-2V4h2z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const ChartGauge = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M15 10a6.93 6.93 0 0 0-1.114-3.764l-.723.722C13.71 7.878 14 8.93 14 10zm-1-5.293L13.293 4 9.01 8.283a1.98 1.98 0 0 0-2.121.054A2 2 0 1 0 10 10a2 2 0 0 0-.283-1.01zM8 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2m0-7c1.07 0 2.121.29 3.042.837l.726-.727A6.988 6.988 0 0 0 1 10h1a6.007 6.007 0 0 1 6-6"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const ChartLine = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2.335 14 5.53 8l3.65 3.245a1 1 0 0 0 1.56-.3L13.5 5.45 12.59 5l-2.745 5.5-3.65-3.245a1 1 0 0 0-1.252-.086 1 1 0 0 0-.298.331L2 12.5V1H1v13a1 1 0 0 0 1 1h13v-1z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const ChartPie = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2m5 5.5H8.5V3.025A5 5 0 0 1 13 7.5M7.71 13a5 5 0 0 1-.21-9.975v4.5a1 1 0 0 0 1 1H13A5 5 0 0 1 7.71 13"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const ChartScatter = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 15h13v-1H2V1H1v13a1 1 0 0 0 1 1m4-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0m1-2.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2m5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0M13 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2M8 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Check = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="M6.5 12 2 7.5l.707-.707L6.5 10.585l6.793-6.792L14 4.5z" clipPath="url(#a)" />
    </svg>
  );
});

const CheckOutline = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M4.111 2.18a7 7 0 1 1 7.778 11.64A7 7 0 0 1 4.111 2.18m.556 10.809a6 6 0 1 0 6.666-9.978 6 6 0 0 0-6.666 9.978M4.5 8.206 7 10.708l4.5-4.5-.707-.707L7 9.293 5.207 7.5z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const ChevronDown = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="M8 11 3 6l.7-.7L8 9.6l4.3-4.3.7.7z" clipPath="url(#a)" />
    </svg>
  );
});

const ChevronLeft = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="m5 8 5-5 .7.7L6.4 8l4.3 4.3-.7.7z" clipPath="url(#a)" />
    </svg>
  );
});

const ChevronRight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="m11 8-5 5-.7-.7L9.6 8 5.3 3.7 6 3z" clipPath="url(#a)" />
    </svg>
  );
});

const ChevronUp = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="m8 5 5 5-.7.7L8 6.4l-4.3 4.3L3 10z" clipPath="url(#a)" />
    </svg>
  );
});

const Code = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M6.21 12.742 8.82 3l.965.26L7.175 13zM.5 8 4 4.5l.705.705L1.915 8l2.79 2.795L4 11.5zm15 0L12 11.5l-.705-.705L14.085 8l-2.79-2.795L12 4.5z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Copy = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M1 9h1V2h7V1H2a1 1 0 0 0-1 1zm13 5V5H5v9zM5 4h9a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const CreditCard = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 3h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1m12 2.5V4H2v1.5zm-12 1V12h12V6.5zM3 10h5v1H3z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Cross = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8.707 8 13 3.707 12.293 3 8 7.293 3.707 3 3 3.707 7.293 8 3 12.293l.707.707L8 8.707 12.293 13l.707-.707z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const CrossSmall = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8.707 8 12 4.707 11.293 4 8 7.293 4.707 4 4 4.707 7.293 8 4 11.293l.707.707L8 8.707 11.293 12l.707-.707z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Dashboard = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1m5 4.5h7V2H7zm-1 0V2H2v3.5zm-4 1V14h12.001V6.5zM5.5 13A2.503 2.503 0 0 1 3 10.5h1A1.5 1.5 0 1 0 5.5 9V8a2.5 2.5 0 1 1 0 5M10 8h1v5h-1zm2 2.5h1V13h-1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const DataDown = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m8.5 6.086 1.793-1.793L11 5 8 8 5 5l.707-.707L7.5 6.086V2h1zM3 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zm0 1v3h10v-3zm7 1.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const DataUp = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M7.5 3.914 5.707 5.707 5 5l3-3 3 3-.707.707L8.5 3.914V8h-1zM3 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zm0 1v3h10v-3zm7 1.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Database = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8 1.5c-2.649 0-5.5.626-5.5 2v9c0 1.374 2.851 2 5.5 2s5.5-.626 5.5-2v-9c0-1.374-2.851-2-5.5-2m0 1c2.899 0 4.397.717 4.498 1-.1.283-1.6 1-4.498 1-2.92 0-4.42-.728-4.5-.991v-.003C3.58 3.228 5.08 2.5 8 2.5M3.5 4.714c1.064.534 2.821.786 4.5.786s3.436-.252 4.5-.786v1.78C12.42 6.772 10.92 7.5 8 7.5c-2.925 0-4.425-.73-4.5-1zm0 3c1.064.534 2.821.786 4.5.786s3.436-.252 4.5-.786v1.78c-.08.278-1.58 1.006-4.5 1.006-2.925 0-4.425-.73-4.5-1zM8 13.5c-2.925 0-4.425-.73-4.5-1v-1.786c1.064.534 2.821.786 4.5.786s3.436-.252 4.5-.786v1.78c-.08.278-1.58 1.006-4.5 1.006"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Dataset = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M7.763 8.94a.5.5 0 0 0 .474 0l6.5-3.5a.5.5 0 0 0 0-.88l-6.5-3.5a.5.5 0 0 0-.474 0l-6.5 3.5a.5.5 0 0 0 0 .88zM8 7.932 2.555 5 8 2.068 13.445 5zM8 12a.5.5 0 0 1-.237-.06l-6.5-3.5.474-.88L8 10.932l6.263-3.372.474.88-6.5 3.5A.5.5 0 0 1 8 12m0 3a.5.5 0 0 1-.237-.06l-6.5-3.5.474-.88L8 13.932l6.263-3.372.474.88-6.5 3.5A.5.5 0 0 1 8 15"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Download = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M12.295 6.295 13 7l-5 5-5-5 .705-.705 3.795 3.79V1h1v9.085zM13 14v-2h1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-2h1v2z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Draggable = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M5 3h2v2H5zm4 0h2v2H9zM7 7H5v2h2zm2 0h2v2H9zm-2 4H5v2h2zm2 0h2v2H9z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Edit = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M12.7 6.5c.4-.4.4-1 0-1.4l-1.8-1.8c-.4-.4-1-.4-1.4 0L2 10.8V14h3.2zM10.2 4 12 5.8l-1.5 1.5-1.8-1.8zM3 13v-1.8l5-5L9.8 8l-5 5z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Email = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M14 3H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1m-1.1 1L8 7.39 3.1 4zM2 12V4.455L7.715 8.41a.5.5 0 0 0 .57 0L14 4.455V12z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Explore = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m11.3 10.6 3.7 3.7-.7.7-3.65-3.65C9.55 12.35 8.1 13 6.5 13c-1.8 0-3.5-.8-4.6-2.15l.75-.65C3.6 11.35 5 12 6.5 12c2.75 0 5-2.25 5-5s-2.25-5-5-5c-1.4 0-2.7.55-3.6 1.6l-.75-.7C3.3 1.65 4.85 1 6.5 1c3.3 0 6 2.7 6 6 0 1.35-.45 2.6-1.2 3.6M3 4.5h4v1H3zM7 7a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M1.5 9.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m3.5-2v-1H1v1zm-3.5-2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m1.5 3v1h4v-1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const External = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="M8 3v1h3.295L3 12.295l.705.705L12 4.705V8h1V3z" clipPath="url(#a)" />
    </svg>
  );
});

const Eye = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M12.533 4.046A8.35 8.35 0 0 1 15.47 7.83a.5.5 0 0 1 0 .34A8.35 8.35 0 0 1 8 13.5 8.35 8.35 0 0 1 .53 8.17a.5.5 0 0 1 0-.34A8.35 8.35 0 0 1 8 2.5a8.35 8.35 0 0 1 4.533 1.546M1.535 8C2.55 10.535 5.35 12.5 8 12.5s5.45-1.965 6.465-4.5C13.45 5.465 10.65 3.5 8 3.5S2.55 5.465 1.535 8m4.798-2.494a3 3 0 1 1 3.334 4.989 3 3 0 0 1-3.334-4.99m.556 4.157A2 2 0 1 0 9.11 6.337a2 2 0 0 0-2.22 3.326"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Filter = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M9 14H7a1 1 0 0 1-1-1V9.205L2.295 5.5A1 1 0 0 1 2 4.795V3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v1.795a1 1 0 0 1-.295.705L10 9.205V13a1 1 0 0 1-1 1M3 3v1.795l4 4V13h2V8.795l4-4V3z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const FitWidth = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 15h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1m0-1V2h12v12zm8.295-7.795L11 5.5 13.5 8 11 10.5l-.705-.705 1.29-1.295h-7.17l1.29 1.295L5 10.5 2.5 8 5 5.5l.705.705L4.415 7.5h7.17z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Fork = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3 6.5V3.707l4.5 4.5V14h1V8.207l4.5-4.5V6.5h1V2H9.5v1h2.793L8 7.293 3.707 3H6.5V2H2v4.5z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Function = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M9.5 2v1H6.957l-.319 3.5H9v1H6.548l-.509 5.59a.995.995 0 0 1-.996.91H2.5v-1h2.543l.5-5.5H3.5v-1h2.134l.327-3.59A.995.995 0 0 1 6.957 2zM12 9h1l-1.451 2.5L13 14h-1l-1-1.9-1 1.9H9l1.453-2.5L9 9h1l1 1.948z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Image = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <g fill="currentColor">
        <path d="M9.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m0-2a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1" />
        <path d="M13 2H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m0 11H3v-3l2.5-2.5 2.795 2.795a1 1 0 0 0 1.41 0L10.5 9.5 13 12zm0-2.415L11.205 8.79a1 1 0 0 0-1.41 0L9 9.585 6.205 6.79a1 1 0 0 0-1.41 0L3 8.585V3h10z" />
      </g>
    </svg>
  );
});

const ImageMissing = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m14.293 1 .707.707-1 1V13a1 1 0 0 1-1 1H2.707l-1 1L1 14.293zM3.707 13H13v-1.501L11 9.5l-.793.793a1 1 0 0 1-1.414 0l-1.19-1.19zm8-4.209L13 10.084V3.707L8.312 8.396 9.5 9.584l.793-.793a1 1 0 0 1 1.414 0M3 9.5V11H2V3a1 1 0 0 1 1-1h8v1H3v5.086l1.793-1.793a1 1 0 0 1 1.414 0l.688.687-.708.708-.687-.686z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Info = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2.293 13.707c.188.187.442.293.707.293h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v10c0 .265.106.52.293.707M3 13V3h10v10zm5-9a.75.75 0 1 0 0 1.5A.75.75 0 0 0 8 4m.5 7V7h-2v1h1v3H6v1h4v-1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Invoice = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10.963 4.808A.5.5 0 0 1 11 5v2h-1V6H7a1 1 0 0 1-1-1V2H2v12h8v-1h1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5a.5.5 0 0 1 .355.145l3.5 3.5a.5.5 0 0 1 .108.163M7 2.205V5h2.795zm6.085 8.295H6.5v-1h6.585l-1.29-1.295.705-.705L15 10l-2.5 2.5-.705-.705z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Key = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8.477 1.48a4.5 4.5 0 0 1 5.431 1.08 4.5 4.5 0 0 1-4.714 7.246L4 15H1v-3l5.194-5.194A4.5 4.5 0 0 1 8.477 1.48m1.007 7.37a3.5 3.5 0 0 0 3.066-.512 3.5 3.5 0 1 0-5.399-1.821l.174.573-.424.424L2 12.414V14h1.586l1.103-1.104L4 12.207l.707-.707.69.69.792-.794-.689-.689.707-.707.69.69 1.59-1.591.424-.424zM12 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Keyboard = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M14 13a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1zM2 12V5h12v7zm3-2h5.5v1H5zM4 6H3v1h1zm1 0h1v1H5zm3 0H7v1h1zm1 0h1v1H9zm-5 4H3v1h1zM3 8h1v1H3zm3 0H5v1h1zm1 0h1v1H7zm6-2h-2v1h2zm-2 2h2v1h-2zm-1 0H9v1h1zm1.5 2H13v1h-1.5z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Lambda = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M5 4h1.85l.603 1.261L3.8 13H5l3-6.5 2.732 5.92a1 1 0 0 0 .908.58H13v-1h-1.25L7.767 3.584A1 1 0 0 0 6.858 3H5z"
      />
    </svg>
  );
});

const Launch = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10 2V1h5v5h-1V2.707L9.707 7 9 6.293 13.293 2zM2.293 13.707c.188.187.442.293.707.293h10a1 1 0 0 0 1-1V8h-1v5H3V3h5V2H3a1 1 0 0 0-1 1v10c0 .265.106.52.293.707"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Library = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 3h9v1H2zm0 3h9v1H2zm6 3H2v1h6zm6 2.5L10.5 9v5z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Link = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M13.65 2.727a3 3 0 0 1 .975.653 3 3 0 0 1 .01 4.24l-4 4a3 3 0 0 1-2.135.88 3 3 0 0 1-2.125-.865 3 3 0 0 1 0-4.25l.71-.71.705.71-.705.71a2.003 2.003 0 0 0 2.835 2.83l4-4a2.005 2.005 0 1 0-2.835-2.835l-.71-.71a3 3 0 0 1 3.275-.653M1.66 11.76a2 2 0 0 0 .436.65 2 2 0 0 0 2.835 0l.71.71a3.04 3.04 0 0 1-2.14.88 3 3 0 0 1-2.13-.85 3 3 0 0 1 0-4.255l4-4A3.009 3.009 0 0 1 9.625 9.15l-1.06 1.06-.71-.71 1.06-1.075A2 2 0 0 0 9.5 7a1.97 1.97 0 0 0-1.22-1.86 2 2 0 0 0-2.185.435l-4 4a2 2 0 0 0-.436 2.185"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const LockClosed = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M12 7h-1V4a3 3 0 0 0-6 0v3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1M6 4a2 2 0 1 1 4 0v3H6zm6 10H4V8h8z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const LockOpen = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M12 7H6V4a2 2 0 1 1 4 0h1a3 3 0 0 0-6 0v3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1m0 7H4V8h8z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const LogoDiscord = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M12.85 3.55q-1.35-.6-2.85-.9h-.05c-.1.2-.25.5-.35.75-1.1-.15-2.15-.15-3.2 0-.1-.25-.25-.5-.35-.75H6q-1.5.225-2.85.9C1.35 6.25.85 8.9 1.1 11.5v.05c1.2.9 2.35 1.4 3.5 1.75h.05c.25-.35.5-.75.7-1.15v-.05c-.4-.15-.75-.3-1.1-.5-.05 0-.05-.05 0-.05.05-.05.15-.1.2-.15h.05c2.3 1.05 4.75 1.05 7.05 0h.05c.05.05.15.1.2.15.05 0 0 .05 0 .05-.35.2-.7.4-1.1.5 0 0-.05.05 0 .05.2.4.45.8.7 1.15h.05c1.15-.35 2.3-.9 3.5-1.75v-.05c.3-3-.5-5.6-2.1-7.95M5.7 9.95c-.7 0-1.25-.65-1.25-1.4S5 7.15 5.7 7.15s1.25.65 1.25 1.4-.55 1.4-1.25 1.4m4.65 0c-.7 0-1.25-.65-1.25-1.4s.55-1.4 1.25-1.4 1.25.65 1.25 1.4-.55 1.4-1.25 1.4"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Maximize = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10 2V1h5v5h-1V2.707L9.707 7 9 6.291 13.293 2zM6.296 9 7 9.708 2.707 14H6v1H1v-5h1v3.293z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Migrate = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3 1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H8.5v3.085l1.295-1.29.705.705L8 10 5.5 7.5l.705-.705L7.5 8.085V5H3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1m2 1H3v2h2zm1 2h7V2H6zm-3.707 7.293A1 1 0 0 1 3 11h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-2a1 1 0 0 1 .293-.707M10 12H3v2h7zm1 2h2v-2h-2z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Minimize = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m14.296 1 .704.708L10.707 6H14v1H9V2h1v3.293zM2 10V9h5v5H6v-3.293L1.707 15 1 14.291 5.293 10z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Moon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m7.49 1.5-.087.008a6.548 6.548 0 0 0 .912 12.99c.082.004.164 0 .245 0a6.54 6.54 0 0 0 5.352-2.777.504.504 0 0 0-.392-.782A6.54 6.54 0 0 1 7.945 2.19a.507.507 0 0 0-.455-.69"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const NodeEnd = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m12.297 8.004-2.293-2.296-2.293 2.296 2.293 2.296zm-2.654-3.35c.2-.2.523-.2.722 0l2.986 2.99a.51.51 0 0 1 0 .72l-2.986 2.99c-.2.2-.523.2-.722 0l-2.846-2.85L2.504 8.5a.5.5 0 1 1 0-1l4.293.004z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const NodeStart = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3.707 8.004 6 5.708l2.293 2.296L6 10.3zm2.654-3.35a.51.51 0 0 0-.722 0l-2.986 2.99a.51.51 0 0 0 0 .72l2.986 2.99c.2.2.523.2.722 0l2.846-2.85L13.5 8.5a.5.5 0 1 0 0-1l-4.293.004z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const NotificationOff = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M4 8.5a.5.5 0 0 1-.147.354L2.208 10.5 1.5 9.793l1.5-1.5V6.5a5.007 5.007 0 0 1 4.5-4.975V.5h1v1.025c.997.1 1.94.5 2.706 1.146l-.713.713A3.96 3.96 0 0 0 8 2.5a4.005 4.005 0 0 0-4 4zm9-2v1.793l1.354 1.354A.5.5 0 0 1 14.5 10v1.5a.5.5 0 0 1-.5.5h-3.5v.5a2.5 2.5 0 0 1-5 0V12h-.793l-3 3L1 14.293 14.293 1l.707.707-2.542 2.542c.356.697.542 1.468.542 2.251m-3.94 7.06a1.5 1.5 0 0 0 .44-1.06V12h-3v.5a1.5 1.5 0 0 0 2.56 1.06M5.708 11H13.5v-.793l-1.354-1.354A.5.5 0 0 1 12 8.5v-2c0-.514-.1-1.023-.294-1.499z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const OverflowMenu = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const PanelLeft = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1M2 3h3v10H2zm12 10H6V3h8z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const PanelRight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1M2 3h8v10H2zm12 10h-3V3h3z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Pause = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M6 12V4H4v8zM4 3h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1m8 9V4h-2v8zm-2-9h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Percentage = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M4.5 7a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0-4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m8.793-1L2 13.293l.707.707L14 2.707zm-3.182 11.579a2.5 2.5 0 1 0 2.778-4.157 2.5 2.5 0 0 0-2.778 4.157m.556-3.326a1.5 1.5 0 1 1 1.667 2.495 1.5 1.5 0 0 1-1.667-2.495"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Play = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3.5 14a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .741-.438l10 5.5a.5.5 0 0 1 0 .876l-10 5.5A.5.5 0 0 1 3.5 14M4 3.345v9.31L12.462 8z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Plug = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M11 4h-.5V1h-1v3h-3V1h-1v3H5a1 1 0 0 0-1 1v3a4.004 4.004 0 0 0 3.5 3.965V15h1v-3.035A4.004 4.004 0 0 0 12 8V5a1 1 0 0 0-1-1m0 4a3 3 0 0 1-6 0V5h6z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Recent = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M5.286 1.55A7 7 0 0 1 8 1a7 7 0 1 1-7 7h1a6 6 0 1 0 1.54-4H6v1H2V1h1v2.115a7 7 0 0 1 2.286-1.564M7.5 8.206 10.295 11l.705-.705L8.5 7.79V3.5h-1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Renew = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10 5.003h2.61a5.5 5.5 0 0 0-6.175-2.27A5.5 5.5 0 0 0 2.5 8h-1a6.49 6.49 0 0 1 4.289-6.113A6.51 6.51 0 0 1 13 3.844V2.006h1v3.996h-4zm-4 5.994H3.39a5.5 5.5 0 0 0 6.175 2.27A5.5 5.5 0 0 0 13.5 8h1a6.49 6.49 0 0 1-4.289 6.113A6.51 6.51 0 0 1 3 12.156v1.838H2V9.998h4z"
      />
    </svg>
  );
});

const Rocket = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8.315 14.965a.5.5 0 0 0 .495-.075l2.5-2a.5.5 0 0 0 .19-.39V8.705l1.89-1.885A5.46 5.46 0 0 0 15 2.93V2a1 1 0 0 0-1-1h-.93a5.46 5.46 0 0 0-3.89 1.61L7.295 4.5H3.5a.5.5 0 0 0-.39.195l-2 2.5a.5.5 0 0 0 .32.81l3.5.5.14-1-2.635-.375L3.74 5.5H7.5a.5.5 0 0 0 .355-.145L9.89 3.32A4.47 4.47 0 0 1 13.07 2H14v.93a4.47 4.47 0 0 1-1.32 3.18l-2.035 2.035a.5.5 0 0 0-.145.355v3.76l-1.62 1.305-.375-2.635-1 .14.5 3.5a.5.5 0 0 0 .31.395m-4.671-3.319 3.999-3.999.707.707-3.999 4z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Scissors = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M13.25 9.815 10.12 8l3.13-1.815a2.5 2.5 0 0 0-.605-4.6 2.5 2.5 0 0 0-2.81 3.68 2.5 2.5 0 0 0 1.195 1.05L9.12 7.42 2 3.3l-.5.87L8.12 8 1.5 11.84l.5.87 7.12-4.13 1.91 1.105a2.5 2.5 0 0 0-1.195 1.05 2.5 2.5 0 0 0 2.81 3.68 2.5 2.5 0 0 0 .605-4.6m-2.55-5.05a1.5 1.5 0 0 1 1.105-2.247 1.5 1.5 0 0 1 1.495.742 1.5 1.5 0 0 1-.55 2.055 1.5 1.5 0 0 1-2.05-.55m2.6 8a1.5 1.5 0 0 1-2.755-.36 1.5 1.5 0 0 1 .155-1.145 1.5 1.5 0 0 1 2.05-.55 1.5 1.5 0 0 1 .55 2.03z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Script = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M4 14h3v1H4a1.003 1.003 0 0 1-1-1V2a1.003 1.003 0 0 1 1-1h5a.45.45 0 0 1 .35.15l3.5 3.5A.46.46 0 0 1 13 5v4h-1V6H9a1.003 1.003 0 0 1-1-1V2H4zm7.8-9L9 2.2V5zm-2.385 8 1.29-1.29L10 11l-2 2 2 2 .71-.705zm4.17 0-1.29 1.29L13 15l2-2-2-2-.71.705z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Search = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m14.5 13.793-3.776-3.776a5.509 5.509 0 1 0-.707.707l3.776 3.776zM2 6.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Settings = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M13.5 8v.38l.945.865a1 1 0 0 1 .205 1.255l-1.14 2a1 1 0 0 1-1.185.45l-1.21-.41q-.318.21-.66.375l-.255 1.26a1 1 0 0 1-1 .805H6.84a1 1 0 0 1-1-.805l-.255-1.26a6 6 0 0 1-.655-.375l-1.215.41q-.155.05-.32.05a1 1 0 0 1-.865-.5l-1.18-2a1 1 0 0 1 .205-1.275l.945-.84V7.62l-.945-.865A1 1 0 0 1 1.35 5.5l1.18-2a1 1 0 0 1 .865-.5 1 1 0 0 1 .32.03l1.19.43a6 6 0 0 1 .66-.375l.255-1.26a1 1 0 0 1 1-.805h2.34a1 1 0 0 1 1 .805l.255 1.26q.34.166.655.375l1.215-.41a1 1 0 0 1 1.185.45l1.18 2a1 1 0 0 1-.19 1.275l-.96.84zm-2.61 3.42 1.715.58 1.18-2-1.36-1.235a4.5 4.5 0 0 0 0-1.565l1.36-1.2-1.18-2-1.72.56a4.7 4.7 0 0 0-1.35-.785L9.18 2H6.82l-.355 1.795a4.4 4.4 0 0 0-1.355.785L3.395 4l-1.18 2 1.36 1.235a4.5 4.5 0 0 0 0 1.565L2.215 10l1.18 2 1.72-.56c.403.335.86.6 1.35.785L6.82 14h2.36l.355-1.795a4.4 4.4 0 0 0 1.355-.785m-4.557-.926A3 3 0 0 0 8 11a2.97 2.97 0 0 0 3-3 3 3 0 1 0-4.667 2.494m.894-4.354c.246-.099.509-.146.773-.14a1.955 1.955 0 0 1 2 2 1.954 1.954 0 0 1-2 2 1.954 1.954 0 0 1-2-2 1.955 1.955 0 0 1 1.227-1.86"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Share = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M11.5 10a2.5 2.5 0 0 0-1.945.945L5.9 8.66a2.23 2.23 0 0 0 0-1.32l3.655-2.285A2.5 2.5 0 1 0 9 3.5q.003.338.1.66L5.445 6.445a2.5 2.5 0 1 0 0 3.11L9.1 11.84a2.4 2.4 0 0 0-.1.66 2.5 2.5 0 1 0 2.5-2.5m0-8a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m-8 7.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m8 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Spinner = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M13.5 7.961A5.5 5.5 0 0 0 8 2.5v-1a6.5 6.5 0 0 1 5.26 10.318l-.001.003-.81-.588a5.47 5.47 0 0 0 1.05-3.272"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Star = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m8 3.26 1.38 2.79.23.5.5.075 3.08.445L11 9.22l-.375.365.09.5.525 3.065-2.755-1.445L8 11.5l-.465.245L4.78 13.17l.5-3.065.09-.5L5 9.22 2.79 7.045 5.87 6.6l.5-.075.23-.5zM8 1 5.725 5.61.64 6.345l3.68 3.59L3.45 15 8 12.61 12.55 15l-.87-5.065 3.68-3.585-5.085-.74z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Subtract = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="M2.5 7.5v1h11v-1z" clipPath="url(#a)" />
    </svg>
  );
});

const Sun = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M7.5 1.002h1v2.5h-1zM3.404 2.7l-.707.707L4.45 5.16l.707-.707zM8 6.002a2 2 0 1 1 0 4 2 2 0 0 1 0-4m0-1a3 3 0 1 0 0 6 3 3 0 0 0 0-6m-7 2.5h2.5v1H1zm3.45 3.344-1.753 1.753.707.707 1.753-1.753zm3.05 1.656h1v2.5h-1zm4.05-1.656-.707.707 1.753 1.753.707-.707zm.95-3.344H15v1h-2.5zm.096-4.803-1.753 1.753.707.707 1.753-1.753z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Support = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M14.113 11.406a6.98 6.98 0 0 0 0-6.812l.094-.094a1 1 0 0 0 0-1.414l-1.293-1.293a1 1 0 0 0-1.414 0l-.094.094a6.99 6.99 0 0 0-6.812 0L4.5 1.793a1 1 0 0 0-1.414 0L1.793 3.086a1 1 0 0 0 0 1.414l.094.094a6.99 6.99 0 0 0 0 6.812l-.094.094a1 1 0 0 0 0 1.414l1.293 1.293a1 1 0 0 0 1.414 0l.094-.094a6.99 6.99 0 0 0 6.812 0l.094.094a1 1 0 0 0 1.414 0l1.293-1.293a1 1 0 0 0 0-1.414zM14 8c0 .925-.215 1.838-.627 2.666l-1.948-1.948c.1-.473.1-.963 0-1.436l1.948-1.948C13.785 6.162 14 7.074 14 8m-.5-4.207L11.033 6.26A3.5 3.5 0 0 0 9.74 4.967L12.207 2.5zM8 10.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m2.658-7.865-1.94 1.94a3.5 3.5 0 0 0-1.436 0l-1.94-1.94a6.12 6.12 0 0 1 5.316 0M3.793 2.5 6.26 4.967c-.537.31-.983.756-1.293 1.293L2.5 3.793zM2 8c0-.925.215-1.837.627-2.666l1.948 1.948c-.1.473-.1.963 0 1.436l-1.948 1.948A6 6 0 0 1 2 8m1.793 5.5L2.5 12.207 4.967 9.74c.31.537.756.983 1.293 1.293zm1.55-.135 1.939-1.94c.473.1.963.1 1.436 0l1.94 1.94a6.12 6.12 0 0 1-5.316 0m6.864.135L9.74 11.033c.537-.31.983-.756 1.293-1.293l2.467 2.467z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Terminal = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3 2.005h10a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1m10 3v-2H3v2zm-10 1v7h10v-7zm3.79 3.5L5.38 8.09l.705-.705 2.12 2.12-2.12 2.12-.705-.705z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Text = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M9 12H8l-.846-2.5H3.847L3 12H2l3-8.5h1zM5.434 4.814 4.186 8.5h2.629L5.567 4.815zM10 6h2.5A1.5 1.5 0 0 1 14 7.5V12h-3a1.5 1.5 0 0 1-1.5-1.5V10A1.5 1.5 0 0 1 11 8.5h2v-1a.5.5 0 0 0-.5-.5H10zm.647 4.853A.5.5 0 0 0 11 11h2V9.5h-2a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .147.353"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Time = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M4.111 13.82a7 7 0 1 0 7.778-11.64A7 7 0 0 0 4.11 13.82m.556-10.809a6 6 0 1 1 6.666 9.978A6 6 0 0 1 4.667 3.01M7.5 8.205 10.295 11l.705-.705L8.5 7.79V3.5h-1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Timer = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M9.5 1h-3v1h3zm-1 4.5h-1V10h1zm4.79-1.705L14 4.5l-1.245 1.245a5.47 5.47 0 1 1-.59-.825zM5.5 12.242a4.5 4.5 0 1 0 5-7.483 4.5 4.5 0 0 0-5 7.483"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Trash = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M6 1h4v1H6zM2 3v1h1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4h1V3zm2 11V4h8v10zm2-8h1v6H6zm3 0h1v6H9z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const TrendDown = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10 13v-1h3.293L9 7.707 6.854 9.854a.5.5 0 0 1-.708 0L1 4.707 1.707 4 6.5 8.793l2.146-2.146a.5.5 0 0 1 .707 0L14 11.293V8h1v5z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const TrendUp = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10 4v1h3.293L9 9.293 6.854 7.147a.5.5 0 0 0-.708 0L1 12.293l.707.707L6.5 8.207l2.146 2.146a.5.5 0 0 0 .707 0L14 5.708V9h1V4z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const TypeBool = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M7.522 12.096c-.78 0-1.248-.024-2.04-.096V3.84c.936-.048 1.308-.06 1.632-.06 2.532 0 3.288.492 3.288 2.16 0 1.14-.264 1.536-1.14 1.74v.072c1.14.324 1.476.804 1.476 2.088 0 1.74-.732 2.256-3.216 2.256m-1.032-.972c.48.06.792.072 1.344.06 1.44-.036 1.872-.372 1.872-1.464 0-1.152-.384-1.488-1.668-1.488H6.49zm0-3.78h1.836c.828-.036 1.068-.324 1.068-1.284s-.3-1.272-1.332-1.344c-.54-.048-.9-.048-1.572-.024z"
      />
    </svg>
  );
});

const TypeNumber = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M5.28 12V3.84h1.212l3.312 6.744h.06l-.156-1.656V3.84h1.008V12H9.492l-3.3-6.744h-.06l.156 1.656V12z"
      />
    </svg>
  );
});

const TypeString = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8.002 12.084c-2.172 0-2.784-.48-2.784-2.22h1.044c.096 1.02.456 1.296 1.764 1.308s1.764-.216 1.764-1.188c0-.984-.48-1.356-2.184-1.896-1.824-.576-2.328-1.056-2.328-2.4 0-1.524.624-1.956 2.676-1.956 2.016 0 2.592.42 2.676 1.932H9.598c-.084-.816-.456-1.02-1.62-1.02-1.332 0-1.704.276-1.704 1.056 0 .816.48 1.104 2.22 1.692 1.788.612 2.292 1.116 2.292 2.604 0 1.608-.6 2.088-2.784 2.088"
      />
    </svg>
  );
});

const TypeTimestamp = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0m1 0A5 5 0 1 1 3 8a5 5 0 0 1 10 0m-5.5.205L9.295 10 10 9.295 8.5 7.79V5h-1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const TypeUnknown = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M7.186 8.88c0-1.956 2.352-.984 2.352-2.832 0-1.212-.264-1.464-1.56-1.464-1.212 0-1.464.192-1.464 1.092H5.506c.012-1.608.432-1.944 2.472-1.944 2.112 0 2.544.396 2.544 2.316 0 2.304-2.376 1.764-2.376 2.832v.648h-.96zM6.874 12v-1.656h1.632V12z"
      />
    </svg>
  );
});

const TypeVirtualField = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M9 13H7a1 1 0 0 1-1-1V9.205L3.295 6.5A1 1 0 0 1 3 5.795V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v1.795a1 1 0 0 1-.295.705L10 9.205V12a1 1 0 0 1-1 1M4 4v1.795l3 3V12h2V8.795l3-3V4z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Undo = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10 5H3.907l1.794-1.793L5 2.5l-3 3 3 3 .701-.707L3.91 6H10a3 3 0 0 1 0 6H6v1h4a4 4 0 1 0 0-8"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const User = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M9.389 2.421A2.5 2.5 0 1 0 6.61 6.58a2.5 2.5 0 0 0 2.78-4.16m-3.334-.83a3.5 3.5 0 1 1 3.888 5.82 3.5 3.5 0 0 1-3.888-5.82M12 15h1v-2.5A3.5 3.5 0 0 0 9.5 9h-3A3.5 3.5 0 0 0 3 12.5V15h1v-2.5A2.5 2.5 0 0 1 6.5 10h3a2.5 2.5 0 0 1 2.5 2.5z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Users = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M10.889 4.663a2 2 0 1 0 2.222-3.326 2 2 0 0 0-2.222 3.326m.555-2.494a1 1 0 1 1 1.112 1.663 1 1 0 0 1-1.112-1.663M9 8h1V7a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1h1V7a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 9 7zm1.889 4.163a2 2 0 1 0 2.222-3.325 2 2 0 0 0-2.222 3.325m.555-2.494a1 1 0 1 1 1.112 1.663 1 1 0 0 1-1.112-1.663M10 15.5H9v-1a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5v1h-1v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5zm-9 0h1v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v1h1v-1A1.5 1.5 0 0 0 5.5 13h-3A1.5 1.5 0 0 0 1 14.5zm1.889-3.337A2 2 0 1 0 5.11 8.838a2 2 0 0 0-2.222 3.325m.555-2.494a1 1 0 1 1 1.112 1.663 1 1 0 0 1-1.112-1.663M1 8h1V7a.5.5 0 0 1 .5-.5h3A.5.5 0 0 1 6 7v1h1V7a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 1 7zm1.889-3.337A2 2 0 1 0 5.11 1.337a2 2 0 0 0-2.22 3.326m.555-2.494a1 1 0 1 1 1.112 1.663 1 1 0 0 1-1.112-1.663"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Warning = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M8.5 4.5h-1V9h1zM8 10a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5" />
        <path
          d="M7.263 14.695a1.04 1.04 0 0 0 1.474 0l5.958-5.958a1.043 1.043 0 0 0 0-1.474L8.737 1.305a1.043 1.043 0 0 0-1.474 0L1.305 7.263a1.043 1.043 0 0 0 0 1.474zM7.97 2.012a.04.04 0 0 1 .06 0l5.958 5.958a.04.04 0 0 1 0 .06L8.03 13.988a.04.04 0 0 1-.06 0L2.012 8.03a.04.04 0 0 1 0-.06z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
});

const SymbolCommandMac = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3.5 5.25a1.75 1.75 0 1 1 3.5 0V6h2v-.75A1.75 1.75 0 1 1 10.75 7H10v2h.75A1.75 1.75 0 1 1 9 10.75V10H7v.75A1.75 1.75 0 1 1 5.25 9H6V7h-.75A1.75 1.75 0 0 1 3.5 5.25M6 6v-.75a.75.75 0 1 0-.75.75zm1 1v2h2V7zm-1 3h-.75a.75.75 0 1 0 .75.75zm4 0v.75a.75.75 0 1 0 .75-.75zm0-4h.75a.75.75 0 1 0-.75-.75z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const SymbolCursor = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3.642 4.265a.6.6 0 0 1 .737-.737l7.402 2.019a.6.6 0 0 1 .184 1.072l-1.63 1.128 1.963 1.963a.625.625 0 0 1 0 .884l-1.59 1.59a.625.625 0 0 1-.884 0L7.86 10.223 6.733 11.85a.6.6 0 0 1-1.072-.184zm1.149.412L6.4 10.576l1.32-1.909 2.546 2.545 1.06-1.06-2.545-2.545 1.908-1.321z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const SymbolOptionMac = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3.5 5h2.17l2.805 6.545a.75.75 0 0 0 .69.455H12.5v-1H9.33L6.525 4.455A.75.75 0 0 0 5.835 4H3.5zM9 5h3.5V4H9z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const SymbolReturn = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8 4v1h3v4H5.415l1.79-1.795L6.5 6.5l-3 3 3 3 .705-.705L5.415 10H11a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const SymbolShift = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8 4.222 4.122 8.489h2.375v3.509h3.006V8.489h2.375zM7.592 3.18a.55.55 0 0 1 .816 0l4.958 5.456a.511.511 0 0 1-.378.855h-2.483v2.757a.75.75 0 0 1-.751.752H6.246a.75.75 0 0 1-.751-.752V9.491H3.012a.511.511 0 0 1-.378-.855z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const CaretRight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path fill="currentColor" d="m6 4 5 4-5 4z" clipPath="url(#a)" />
    </svg>
  );
});

const Config = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m8.354 1.147 3.396 3.396c.245.245.317.61.185.93a.85.85 0 0 1-.789.527H8c-.551 0-1-.449-1-1V2H3v12h4v1H3c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1h5a.5.5 0 0 1 .354.147M8 2.207V5h2.793zM13.95 11H15v1h-1.05c-.065.313-.19.61-.366.877l.745.744-.708.707-.745-.744a2.5 2.5 0 0 1-.876.365V15h-1v-1.05a2.5 2.5 0 0 1-.876-.366l-.745.745-.707-.708.744-.745A2.5 2.5 0 0 1 9.051 12H8v-1h1.05c.065-.313.19-.61.367-.876l-.745-.745.707-.707.745.744c.265-.177.563-.301.876-.365V8h1v1.05c.313.065.61.19.877.367l.744-.745.707.707-.744.745c.177.265.301.563.365.876m-3.95.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Cube = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m14.252 4.068-6-3.5a.5.5 0 0 0-.504 0l-6 3.5A.5.5 0 0 0 1.5 4.5v7a.5.5 0 0 0 .248.432l6 3.5a.5.5 0 0 0 .504 0l6-3.5a.5.5 0 0 0 .248-.432v-7a.5.5 0 0 0-.248-.432M8 1.578 13.008 4.5 8 7.421 2.992 4.5zM2.5 5.372l5 2.916v5.842l-5-2.916zm6 8.758V8.287l5-2.916v5.842z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Destination = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M11.5 11h.25a3.25 3.25 0 0 0 .65-6.43 4.5 4.5 0 0 0-8.8 0A3.25 3.25 0 0 0 4.25 11h.25v-1h-.25a2.252 2.252 0 1 1-.18-4.5h.43l.01-.41a3.5 3.5 0 0 1 6.94 0l.05.41h.43a2.252 2.252 0 0 1-.18 4.5h-.25zM6.205 9.705 5.5 9 8 6.5 10.5 9l-.705.705L8.5 8.415V14.5h-1V8.415z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const DownToBottom = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m3 4 5 5 5-5-.7-.7L8 7.6 3.7 3.3zm-1 8v-1h12v1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const EventsExamined = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="m6.15 2.3-.3-.95A6.3 6.3 0 0 1 8 1v1a5.7 5.7 0 0 0-1.85.3m-2.3.05a7.4 7.4 0 0 0-1.5 1.55l.8.6a6.6 6.6 0 0 1 1.3-1.35zM2.3 6.15l-.95-.3A6.3 6.3 0 0 0 1 8h1a5.7 5.7 0 0 1 .3-1.85m.05 6a7.2 7.2 0 0 1-1-1.95l.95-.3c.202.588.489 1.144.85 1.65zm1.55 1.5a7.2 7.2 0 0 0 1.95 1l.3-.95a6.4 6.4 0 0 1-1.65-.85zm8.2 0a7.6 7.6 0 0 0 1.55-1.55l-.8-.6a5.8 5.8 0 0 1-1.35 1.35zm2.55-3.5-.95-.3A5.7 5.7 0 0 0 14 8h1a7.7 7.7 0 0 1-.35 2.15M14.6 5.8a7.2 7.2 0 0 0-1-1.95l-.8.6c.361.506.648 1.062.85 1.65zm-4.5-4.5a7.2 7.2 0 0 1 1.95 1l-.6.8a6.5 6.5 0 0 0-1.65-.85zm.05 13.35-.3-.95c-.596.2-1.221.302-1.85.3v1c.727-.043 1.447-.16 2.15-.35M4.936 9.062A3 3 0 0 1 4.5 7.5a2.999 2.999 0 1 1 5.443 1.736l1.557 1.557-.707.707-1.557-1.557a2.995 2.995 0 0 1-4.3-.881m.901-2.673a2 2 0 1 0 3.326 2.222A2 2 0 0 0 5.837 6.39"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const EventsMatched = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3.85 2.35a7.4 7.4 0 0 0-1.5 1.55l.8.6a6.6 6.6 0 0 1 1.3-1.35zM2.3 6.15l-.95-.3A6.3 6.3 0 0 0 1 8h1a5.7 5.7 0 0 1 .3-1.85m-.95 4.05a7.2 7.2 0 0 0 1 1.95l.8-.6A6.4 6.4 0 0 1 2.3 9.9zm2.55 3.45a7.2 7.2 0 0 0 1.95 1l.3-.95a6.5 6.5 0 0 1-1.65-.85zm1.95-12.3.3.95A5.7 5.7 0 0 1 8 2V1a6.3 6.3 0 0 0-2.15.35m6.25 12.3a7.6 7.6 0 0 0 1.55-1.55l-.8-.6a5.8 5.8 0 0 1-1.35 1.35zm1.6-3.8.95.3c.217-.697.335-1.42.35-2.15h-1a5.7 5.7 0 0 1-.3 1.85m.9-4.05a7.2 7.2 0 0 0-1-1.95l-.8.6c.361.506.648 1.062.85 1.65zm-2.55-3.5a7.2 7.2 0 0 0-1.95-1l-.3.95a6.5 6.5 0 0 1 1.65.85zm-1.9 12.35-.3-.95A5.7 5.7 0 0 1 8 14v1c.727-.043 1.447-.16 2.15-.35M7 10.707l-2.5-2.5.707-.707L7 9.293 10.793 5.5l.707.707z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const EventsReturned = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3.85 2.35a7.4 7.4 0 0 0-1.5 1.55l.8.6a6.6 6.6 0 0 1 1.3-1.35zM2.3 6.15l-.95-.3A6.3 6.3 0 0 0 1 8h1a5.7 5.7 0 0 1 .3-1.85m-.95 4.05a7.2 7.2 0 0 0 1 1.95l.8-.6A6.4 6.4 0 0 1 2.3 9.9zm2.55 3.45a7.2 7.2 0 0 0 1.95 1l.3-.95a6.5 6.5 0 0 1-1.65-.85zm1.95-12.3.3.95A5.7 5.7 0 0 1 8 2V1a6.3 6.3 0 0 0-2.15.35m6.25 12.3a7.6 7.6 0 0 0 1.55-1.55l-.8-.6a5.8 5.8 0 0 1-1.35 1.35zm1.6-3.8.95.3c.217-.697.335-1.42.35-2.15h-1a5.7 5.7 0 0 1-.3 1.85m.9-4.05a7.2 7.2 0 0 0-1-1.95l-.8.6c.361.506.648 1.062.85 1.65zm-2.55-3.5a7.2 7.2 0 0 0-1.95-1l-.3.95a6.5 6.5 0 0 1 1.65.85zm-1.9 12.35-.3-.95A5.7 5.7 0 0 1 8 14v1c.727-.043 1.447-.16 2.15-.35M6 9.5H5v1h1zm5 0H7v1h4zm-5-2H5v1h1zm5 0H7v1h4zm-5-2H5v1h1zm5 0H7v1h4z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const FlowOverview = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M6 2h4v1H6zm4 2H6v1h4zM6 6h4v1H6zM1 8h4v1H1zm0 2h4v1H1zm4 2H1v1h4zm0 2H1v1h4zm1 0h4v1H6zm4-2H6v1h4zm0-2H6v1h4zm0-2H6v1h4zm5 6h-4v1h4zm-4-2h4v1h-4z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Flows2 = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M7.748.568a.5.5 0 0 1 .504 0l6 3.5a.5.5 0 0 1 .248.432v4h-1V4.787L8 1.58 2.5 4.787v6.426L8 14.42l5.248-3.061.504.864-5.5 3.208a.5.5 0 0 1-.504 0l-6-3.5A.5.5 0 0 1 1.5 11.5v-7a.5.5 0 0 1 .248-.432zM9.35 8.65c.2-.1.4-.15.65-.15.85 0 1.5.65 1.5 1.5s-.65 1.5-1.5 1.5-1.5-.65-1.5-1.5c0-.25.05-.45.15-.65l-2-2c-.2.1-.4.15-.65.15-.85 0-1.5-.65-1.5-1.5S5.15 4.5 6 4.5s1.5.65 1.5 1.5c0 .25-.05.45-.15.65zM6 5.5c-.3 0-.5.2-.5.5s.2.5.5.5.5-.2.5-.5-.2-.5-.5-.5M9.5 10c0 .3.2.5.5.5s.5-.2.5-.5-.2-.5-.5-.5-.5.2-.5.5"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const GlobeWire = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1m6 6.5h-3a12.1 12.1 0 0 0-1.395-5.275A6 6 0 0 1 14 7.5M8 14a3 3 0 0 1-.335 0A10.9 10.9 0 0 1 6 8.5h4A10.9 10.9 0 0 1 8.35 14 2.5 2.5 0 0 1 8 14M6 7.5c.05-1.948.62-3.847 1.65-5.5a3 3 0 0 1 .67 0A10.9 10.9 0 0 1 10 7.5zm.38-5.275A12.1 12.1 0 0 0 5 7.5H2a6 6 0 0 1 4.395-5.275zM2.025 8.5h3A12.1 12.1 0 0 0 6.4 13.775 6 6 0 0 1 2.025 8.5m7.58 5.275A12.1 12.1 0 0 0 11 8.5h3a6 6 0 0 1-4.395 5.275"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const IncompleteCanceled = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2.757 11.243A6 6 0 0 0 7 13V1a6 6 0 0 0-4.243 10.243M14.295 10l.705.705-1.795 1.795L15 14.295l-.705.705-1.795-1.795L10.705 15 10 14.295l1.795-1.795L10 10.705l.705-.705 1.795 1.795zM8.913 2.382c.606.252 1.157.62 1.621 1.084l.707-.707a6 6 0 0 0-1.945-1.3zm3.629 2.323C12.845 5.432 13 6.212 13 7h-1c0-.657-.13-1.307-.382-1.913z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const MonitorAnomaly = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 15h13v-1H2V1H1v13a1 1 0 0 0 1 1M13 3V2h-3v1h1v1.092a1.492 1.492 0 0 0 0 2.816V9h-1v1h3V9h-1V6.908a1.492 1.492 0 0 0 0-2.816V3zM7 5V4H4v1h1v2.092a1.492 1.492 0 0 0 0 2.816V12H4v1h3v-1H6V9.908a1.491 1.491 0 0 0 0-2.816V5z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const MonitorMatch = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M8.5 1v6.5H15v1H8.5V15h-1V8.5H1v-1h6.5V1zm1.7 2.3 1.3 1.3 2.8-2.8.7.7L11.5 6l-2-2zM5.3 2l.7.7L4.7 4 6 5.3l-.7.7L4 4.7 2.7 6 2 5.3 3.3 4 2 2.7l.7-.7L4 3.3zm.7 8.7-.7-.7L4 11.3 2.7 10l-.7.7L3.3 12 2 13.3l.7.7L4 12.7 5.3 14l.7-.7L4.7 12z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const MonitorThreshold = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 14V8.5h1v-1H2V1H1v13a1 1 0 0 0 1 1h13v-1zm3.776-2c1.8 0 2.514-2.088 3.206-4.106v-.001C9.637 5.979 10.316 4 11.776 4a1.72 1.72 0 0 1 1.526 1.16l.948-.318C14.225 4.767 13.615 3 11.776 3 9.6 3 8.77 5.427 8.036 7.568v.001C7.43 9.333 6.86 11 5.776 11a1.72 1.72 0 0 1-1.527-1.16l-.948.318c.025.075.636 1.842 2.475 1.842M4 7.5h1v1H4zm3 0H6v1h1zm3 0h1v1h-1zm3 0h-1v1h1zm1 0h1v1h-1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Monitors = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M7.748 15.432a.5.5 0 0 0 .504 0l6-3.5a.5.5 0 0 0 .248-.432v-4h-1v3.713L8 14.42l-5.5-3.208V4.787L8 1.58l5.748 3.353.504-.864-6-3.5a.5.5 0 0 0-.504 0l-6 3.5A.5.5 0 0 0 1.5 4.5v7a.5.5 0 0 0 .248.432zM6.698 11.9a.5.5 0 0 0 .783-.262L9 6.32l.52 1.817A.5.5 0 0 0 10 8.5h2v-1h-1.623l-.896-3.137a.5.5 0 0 0-.962 0L7 9.68l-.52-1.817A.5.5 0 0 0 6 7.5H4v1h1.623l.896 3.137a.5.5 0 0 0 .18.262"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

export const Notification = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M14.354 9.647 13 8.293V6.5a5.007 5.007 0 0 0-4.5-4.975V.5h-1v1.025A5.007 5.007 0 0 0 3 6.5v1.793L1.646 9.647A.5.5 0 0 0 1.5 10v1.5a.5.5 0 0 0 .5.5h3.5v.388a2.576 2.576 0 0 0 2.25 2.6A2.5 2.5 0 0 0 10.5 12.5V12H14a.5.5 0 0 0 .5-.5V10a.5.5 0 0 0-.146-.354M9.5 12.5a1.5 1.5 0 0 1-3 0V12h3zm4-1.5h-11v-.793l1.354-1.354A.5.5 0 0 0 4 8.5v-2a4 4 0 0 1 8 0v2a.5.5 0 0 0 .146.354l1.354 1.353z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const PanelFields = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 2h12a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1m7 1H2v10h7zm1 10h4V3h-4zm1-9h2v1h-2zm0 2h2v1h-2zm2 2h-2v1h2zm-2 2h2v1h-2z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const PanelRightShort = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1M2 3h12v4h-4a1 1 0 0 0-1 1v5H2zm12 10h-4V8h4z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const PanelRightTall = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1M2 3h7v10H2zm12 10h-4V3h4z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const Popup = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M5 2h9a1.003 1.003 0 0 1 1 1v7a1.003 1.003 0 0 1-1 1H5a1.003 1.003 0 0 1-1-1V3a1.003 1.003 0 0 1 1-1m0 8h9V3H5zm-3 3h7v-1h1v1a1.003 1.003 0 0 1-1 1H2a1.003 1.003 0 0 1-1-1V8a1.003 1.003 0 0 1 1-1h1v1H2z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Statistic = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M9 2h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H9V6h2V5H9.5V4H11V3H9zm4 3.5L14 4l1 1.5zm-9.167 8.251L1 11.224l.666-.746 2.82 2.516L8.331 9.27a.964.964 0 0 1 1.337-.004l1.812 1.732 2.79-2.987.73.683-2.79 2.988a.997.997 0 0 1-1.42.04l-1.788-1.71-3.833 3.71a.98.98 0 0 1-1.336.028M8 7H5V5a1 1 0 0 1 1-1h1V3H5V2h2a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6v1h2zM3 2v4h1v1H1V6h1V3.5H1v-1h1V2z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const TableStream = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M3 1.5h10c.827 0 1.5.673 1.5 1.5v7h-6v4.5H3c-.827 0-1.5-.673-1.5-1.5V3c0-.827.673-1.5 1.5-1.5m10 1H3a.5.5 0 0 0-.5.5v1.5h11V3a.5.5 0 0 0-.5-.5M7.5 9V5.5h-5V9zM3 13.5h4.5V10h-5v3a.5.5 0 0 0 .5.5m5.5-8V9h5V5.5zm6.5 8L11.5 11v5z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const TableView = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M13.5 1.5h-11a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1m0 1v2h-11v-2zm-5 3h5V9h-5zM7.5 9h-5V5.5h5zm-5 1h5v3.5h-5zm6 3.5V10h5v3.5z"
        clipPath="url(#a)"
      />
    </svg>
  );
});

const UpToTop = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M2 4h12v1H2zm1 8 5-5 5 5-.7.7L8 8.4l-4.3 4.3z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const CalendarTime = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M13.707 2.293A1 1 0 0 1 14 3v3h-1V3h-2v1h-1V3H6v1H5V3H3v10h2v1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h2V1h1v1h4V1h1v1h2a1 1 0 0 1 .707.293M10.5 15a4 4 0 1 1 0-8 4 4 0 0 1 0 8m0-7a3 3 0 1 0 0 6 3 3 0 0 0 0-6m.795 4.5L10 11.205V9h1v1.795l1 1z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

const Insights = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props} ref={ref}>
      <path
        fill="currentColor"
        d="M6.5 2c-.515 0-1.016.074-1.49.22a12 12 0 0 0-.44-.906A6 6 0 0 1 6.5 1c3.3 0 6 2.7 6 6 0 1.35-.45 2.6-1.2 3.6l3.7 3.7-.7.7-3.65-3.65C9.55 12.35 8.1 13 6.5 13a6.1 6.1 0 0 1-1.93-.315q.24-.443.441-.907A5.015 5.015 0 0 0 11.5 7c0-2.75-2.25-5-5-5m-2.98 8.638a.5.5 0 0 0 .96 0L6 5.32l.52 1.817A.5.5 0 0 0 7 7.5h2v-1H7.377l-.896-3.137a.5.5 0 0 0-.962 0L4 8.68l-.52-1.817A.5.5 0 0 0 3 6.5H1v1h1.623z"
        clipPath="url(#a)"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </svg>
  );
});

export const customIcon = {
  Add: Add,
  ArrowDown: ArrowDown,
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  ArrowUp: ArrowUp,
  Bolt: Bolt,
  Book: Book,
  Bookmark: Bookmark,
  BracketsCurly: BracketsCurly,
  BracketsSquare: BracketsSquare,
  Calendar: Calendar,
  CalendarTime: CalendarTime,
  CaretDown: CaretDown,
  CaretRight: CaretRight,
  CaretUp: CaretUp,
  ChartArea: ChartArea,
  ChartBar: ChartBar,
  ChartColumn: ChartColumn,
  ChartGauge: ChartGauge,
  ChartLine: ChartLine,
  ChartPie: ChartPie,
  ChartScatter: ChartScatter,
  Check: Check,
  CheckOutline: CheckOutline,
  ChevronDown: ChevronDown,
  ChevronLeft: ChevronLeft,
  ChevronRight: ChevronRight,
  ChevronUp: ChevronUp,
  Code: Code,
  Config: Config,
  Copy: Copy,
  CreditCard: CreditCard,
  Cross: Cross,
  CrossSmall: CrossSmall,
  Cube: Cube,
  Dashboard: Dashboard,
  DataDown: DataDown,
  DataUp: DataUp,
  Database: Database,
  Dataset: Dataset,
  Destination: Destination,
  Download: Download,
  DownToBottom: DownToBottom,
  Draggable: Draggable,
  Edit: Edit,
  Email: Email,
  Explore: Explore,
  EventsExamined: EventsExamined,
  EventsMatched: EventsMatched,
  EventsReturned: EventsReturned,
  External: External,
  Eye: Eye,
  Filter: Filter,
  FitWidth: FitWidth,
  FlowOverview: FlowOverview,
  Flows2: Flows2,
  Fork: Fork,
  Function: Function,
  GlobeWire: GlobeWire,
  Image: Image,
  ImageMissing: ImageMissing,
  IncompleteCanceled: IncompleteCanceled,
  Info: Info,
  Insights: Insights,
  Invoice: Invoice,
  Key: Key,
  Keyboard: Keyboard,
  Lambda: Lambda,
  Launch: Launch,
  Library: Library,
  Link: Link,
  LockClosed: LockClosed,
  LockOpen: LockOpen,
  LogoDiscord: LogoDiscord,
  Maximize: Maximize,
  Migrate: Migrate,
  Minimize: Minimize,
  MonitorAnomaly: MonitorAnomaly,
  MonitorMatch: MonitorMatch,
  MonitorThreshold: MonitorThreshold,
  Monitors: Monitors,
  Moon: Moon,
  NodeEnd: NodeEnd,
  NodeStart: NodeStart,
  Notification: Notification,
  NotificationOff: NotificationOff,
  OverflowMenu: OverflowMenu,
  PanelFields: PanelFields,
  PanelLeft: PanelLeft,
  PanelRight: PanelRight,
  PanelRightShort: PanelRightShort,
  PanelRightTall: PanelRightTall,
  Pause: Pause,
  Percentage: Percentage,
  Play: Play,
  Plug: Plug,
  Popup: Popup,
  Recent: Recent,
  Renew: Renew,
  Rocket: Rocket,
  Scissors: Scissors,
  Script: Script,
  Search: Search,
  Settings: Settings,
  Share: Share,
  Spinner: Spinner,
  Star: Star,
  Statistic: Statistic,
  Subtract: Subtract,
  Sun: Sun,
  Support: Support,
  SymbolCommandMac: SymbolCommandMac,
  SymbolCursor: SymbolCursor,
  SymbolOptionMac: SymbolOptionMac,
  SymbolReturn: SymbolReturn,
  SymbolShift: SymbolShift,
  TableStream: TableStream,
  TableView: TableView,
  Terminal: Terminal,
  Text: Text,
  Time: Time,
  Timer: Timer,
  Trash: Trash,
  TrendDown: TrendDown,
  TrendUp: TrendUp,
  TypeBool: TypeBool,
  TypeNumber: TypeNumber,
  TypeString: TypeString,
  TypeTimestamp: TypeTimestamp,
  TypeUnknown: TypeUnknown,
  TypeVirtualField: TypeVirtualField,
  Undo: Undo,
  UpToTop: UpToTop,
  User: User,
  Users: Users,
  Warning: Warning,
} as const;

export type CustomIcon = keyof typeof customIcon;
export const customIconNames = Object.keys(customIcon) as CustomIcon[];

export const AxiIcons = Object.keys(customIcon).reduce(
  (acc, key) => {
    // @ts-expect-error
    acc[key as keyof typeof customIcon] = key;
    return acc;
  },
  {} as { [K in keyof typeof customIcon]: K }
);
