// cSpell:ignore devtools
import { useWillUnmount } from '@better-hooks/lifecycle';
import { observer } from 'mobx-react-lite';
import { SessionProvider } from 'next-auth/react';
import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';

import { axiom } from './axiom';
import { ErrorBoundary } from './components/ErrorBoundary';
import { FeatureFlagProvider } from './components/FeatureFlagProvider';
import { InitAxios } from './components/InitAxios';
import { Loading } from './components/Loading';
import { MainViewport } from './components/MainViewport';
import { PortalStore, PortalStoreContext } from './components/primitives/Portals';
import { TooltipProvider } from './components/primitives/Tooltip';
import { NOT_TESTING, TanstackQueryTrpcProvider } from './contexts/TanstackQueryTrpc';
import { Route } from './routes/Route';
import { UserPreferencesStore, UserPreferencesStoreContext } from './stores/UserPreferencesStore';
import { userProfileStore, UserProfileStoreContext } from './stores/UserProfileStore';
import { browserHistory } from './util/browserHistory';
import { FeatureFlagsSelector } from './util/featureFlags/FeatureFlagSelector';
import { useDebuggedLocalObservable } from './util/hooks/mobx/useDebuggedLocalObservable';
import { LocationHistoryProvider } from './util/LocationHistoryProvider';
import { UseSession } from './util/UseSession';

// do it this way so webpack will exclude it on production builds
const DevTools =
  process.env.MOBX_DEV_TOOLS === 'true' ? await import('mobx-react-devtools').then((m) => m.default) : null;

const LazyAuthed = React.lazy(async () => import('./routes/Authed'));
const LazyPlay = React.lazy(async () => import('./routes/Play'));
const LazyPublic = React.lazy(async () => import('./routes/Public'));

export const MainRouter = observer(function MainRouter() {
  const userPreferencesStore = useDebuggedLocalObservable(() => new UserPreferencesStore());
  const portalStore = useDebuggedLocalObservable(() => new PortalStore());

  useWillUnmount(() => {
    userPreferencesStore.dispose();
  });

  return (
    <SessionProvider basePath="/frapi/auth">
      <HelmetProvider>
        <Router history={browserHistory}>
          <LocationHistoryProvider>
            <TanstackQueryTrpcProvider>
              <FeatureFlagProvider>
                <UseSession>
                  {(session) => (
                    <InitAxios session={session}>
                      {/* Provider should stay "under" `UseSession` and `InitAxios` in order for Axios to be correctly configured */}
                      <UserProfileStoreContext.Provider value={userProfileStore}>
                        <UserPreferencesStoreContext.Provider value={userPreferencesStore}>
                          <PortalStoreContext.Provider value={portalStore}>
                            <TooltipProvider>
                              <MainViewport>
                                <ErrorBoundary>
                                  <Suspense fallback={<Loading />}>
                                    <Route
                                      path="/"
                                      component={(props: any) => {
                                        if (axiom.playMode) {
                                          return <LazyPlay {...props} />;
                                        } else {
                                          switch (session.status) {
                                            case 'loading':
                                              return <Loading />;
                                            case 'authenticated':
                                              return <LazyAuthed {...props} />;
                                            default:
                                              return <LazyPublic {...props} />;
                                          }
                                        }
                                      }}
                                    />
                                  </Suspense>
                                </ErrorBoundary>
                                {DevTools && (
                                  <div className="snap-remove">
                                    <DevTools position={{ bottom: 0, right: 20 }} />
                                  </div>
                                )}
                              </MainViewport>
                            </TooltipProvider>
                          </PortalStoreContext.Provider>
                        </UserPreferencesStoreContext.Provider>
                      </UserProfileStoreContext.Provider>
                    </InitAxios>
                  )}
                </UseSession>
                {NOT_TESTING && <FeatureFlagsSelector />}
              </FeatureFlagProvider>
            </TanstackQueryTrpcProvider>
          </LocationHistoryProvider>
        </Router>
      </HelmetProvider>
    </SessionProvider>
  );
});
