import { action, observable, makeObservable, type IReactionDisposer, reaction } from 'mobx';
import { createContext } from 'react';

import { isAxiomer } from '../util/featureFlags/featureFlags';
import { checkFeatureFlag, ldClient } from '../util/featureFlags/useFeatureFlags';
import { safeLocalStorage } from '../util/safeLocalStorage';

import { StoreBase } from './StoreBase';

const LOCAL_STORAGE_KEY = 'userPreferences';

// TODO: @cje - move this into postgres?
type UserPreferences = {
  showSidebarNullValues?: boolean;
  queryMode?: 'batched' | 'progressive';
};

export class UserPreferencesStore extends StoreBase {
  @observable
  public showSidebarNullValues = false;

  @observable
  public queryMode?: 'batched' | 'progressive';

  @observable
  private progressiveQueriesFlag = false;

  private progressiveQueriesFlagDisposer: IReactionDisposer;

  constructor() {
    super();

    makeObservable(this);

    this.progressiveQueriesFlag = ldClient.variation('progressive-queries', false);

    ldClient.on('change:progressive-queries', (value) => {
      this.progressiveQueriesFlag = value;
    });

    this.progressiveQueriesFlagDisposer = reaction(
      () => this.progressiveQueriesFlag,
      (enabled: boolean) => {
        if (enabled) {
          this.loadProgressiveBasedOnFlag();
        } else {
          this.removeProgressiveFromSettings();
        }
      }
    );

    this.loadPreferences();
  }

  public dispose() {
    this.progressiveQueriesFlagDisposer?.();
  }

  @action.bound
  private loadPreferences() {
    try {
      const userPreferences = JSON.parse(safeLocalStorage.getItem(LOCAL_STORAGE_KEY)!) as UserPreferences;

      if (userPreferences) {
        this.showSidebarNullValues = userPreferences.showSidebarNullValues ?? false;
        this.queryMode = userPreferences.queryMode ?? undefined;
      }
    } catch (error) {
      console.warn('Failed to load user preferences settings from localStorage.');
    }
  }

  @action.bound
  private loadProgressiveBasedOnFlag() {
    if (this.progressiveQueriesFlag && this.queryMode === undefined) {
      if (isAxiomer()) {
        this.updateQueryMode('progressive');
      } else {
        this.updateQueryMode('batched');
      }
    }
  }

  @action.bound
  private removeProgressiveFromSettings() {
    try {
      const userPreferences = JSON.parse(safeLocalStorage.getItem(LOCAL_STORAGE_KEY)!) as UserPreferences;
      if (userPreferences) {
        delete userPreferences.queryMode;

        safeLocalStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...userPreferences }));
      }
    } catch (error) {
      console.warn('Failed to load user preferences settings from localStorage.');
    }
  }

  @action.bound
  public updateShowSidebarNullValues(showSidebarNullValues: boolean): void {
    this.showSidebarNullValues = showSidebarNullValues;
    this.saveUserPreferences();
  }

  @action.bound
  public updateQueryMode(queryMode: 'batched' | 'progressive'): void {
    const progressiveQueriesEnabledLD = checkFeatureFlag('progressive-queries');

    if (progressiveQueriesEnabledLD) {
      this.queryMode = queryMode;
      this.saveUserPreferences();
    }
  }

  private saveUserPreferences() {
    const progressiveQueriesEnabledLD = checkFeatureFlag('progressive-queries');

    safeLocalStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        showSidebarNullValues: this.showSidebarNullValues,
        ...(progressiveQueriesEnabledLD ? { queryMode: this.queryMode } : {}),
      })
    );
  }
}

export const UserPreferencesStoreContext = createContext<UserPreferencesStore | null>(null);
UserPreferencesStoreContext.displayName = 'UserPreferencesStoreContext';
