// cSpell:ignore smhdw tzdb Гринуич
import { getTimeZones, type TimeZone } from '@vvo/tzdb';
import moment, { type DurationInputArg2 } from 'moment-timezone';
import Sugar from 'sugar-date';
import { z } from 'zod';

import { type LicenseTier } from '../stores/CommonTypes';

import { Logger } from './logger';
import { selectUnit } from './selectUnit';

const FANCY_TIME_REGEX = /^now-\d+[smhdwMy]$/;
const HAS_TIME_REGEX =
  /(([0]?[1-9]|1[0-9]|2[0-4])(:|\.)[0-5][0-9]((:|\.)[0-5][0-9])?( )?(AM|am|aM|Am|PM|pm|pM|Pm)?)|(([0-9]|[1][0-9]|[2][0-3])(\s{0,1})(AM|PM|am|pm|aM|Am|pM|Pm{2,2}))/;
const RELATIVE_DAY_REGEX = /^(today|now|tomorrow|yesterday|last|in |next )|( in | from | tomorrow | last )/i;
const SPECIFIC_DAY_REGEX =
  / (Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)|(January|February|March|April|May|June|July|August|September|October|November|December)/i;
const SKIP_TIME_ADJUSTMENT_REGEX = /ago$/i;
const ADJUST_BEGINNING_OR_END_TIME_REGEX = /( end | beginning )/i;
const INVALID_DATE_REGEX = /( from | after )/i;
const IN_QUANTITY_REGEX = /^(in ).* (years|months|weeks|days|hours|minutes|seconds|milliseconds)/i;
const TIMEZONE_REGEX =
  /([+-][01]\d:?[0-5]\d)|([A-Z]{3,10})|(\d{3}[Zz])$|([0-1][0-9]|2[0-4])(:|\.)[0-5][0-9]((:|\.)[0-5][0-9])[Zz]/;

export const MAX_DATE = new Date(9223372036000);
export const MIN_DATE = new Date(0);

const abbreviatedTimeReducer = (accum: any, key: string): Record<string, { singular: string; plural: string }> => {
  // Convert key into variables that moment math can use.
  const unit = key.substring(key.length - 1);
  switch (unit) {
    case 's':
      accum[key] = {
        singular: 'sec',
        plural: 'secs',
      };
      break;

    case 'm':
      accum[key] = {
        singular: 'min',
        plural: 'mins',
      };
      break;

    case 'h':
      accum[key] = {
        singular: 'hr',
        plural: 'hrs',
      };
      break;

    case 'd':
      accum[key] = {
        singular: 'day',
        plural: 'days',
      };
      break;

    case 'w':
      accum[key] = {
        singular: 'week',
        plural: 'weeks',
      };
      break;

    default:
      accum[key] = {
        singular: 'unknown',
        plural: 'unknowns',
      };
      break;
  }

  return accum;
};

export const epochToISO = (epoch: number, timezone: string) => {
  return moment(new Date(epoch * 1000))
    .tz(timezone)
    .toISOString();
};

export const timeLengthToSeconds = (resolution: string) => {
  if (!resolution.length) {
    return undefined;
  }

  const matcher = /^(\d+)([smhdw])$/;
  const matches = resolution.match(matcher);

  if (!matches || matches.length !== 3) {
    return undefined;
  }

  const count = Number(matches[1]);
  switch (matches[2]) {
    case 's':
      return count;
    case 'm':
      return count * 60;
    case 'h':
      return count * 3600;
    case 'd':
      return count * 3600 * 24;
    case 'w':
      return count * 3600 * 24 * 7;
    default:
      return undefined;
  }
};

export function browserSupportsTimezone(timezone: string) {
  try {
    // The `date-time-format-timezone` pollyfill should prevent this, but just in case.
    new Date().toLocaleTimeString('en', { timeZone: timezone });

    return true;
  } catch (e) {
    return false;
  }
}

export function formatDate(
  timeZone: string,
  timestamp: number,
  opts?: Intl.DateTimeFormatOptions,
  locale?: string
): string {
  return new Intl.DateTimeFormat(
    locale,
    opts ? { timeZone: timeZone, ...opts } : { timeZone: timeZone, month: 'short', day: 'numeric', year: 'numeric' }
  )
    .format(timestamp)
    .replace(/[^ -~]/g, ''); // Remove some garbage characters \u200e that IE11 seems to add.
}

export function formatDateTime(
  timeZone: string,
  timestamp?: FancyTime,
  withSeconds = true,
  withTZ = false,
  withCurrentYear = true,
  locale?: string
): string {
  const parsedTimestamp = parseDate(timestamp);
  if (timestamp && parsedTimestamp) {
    const currentYear = new Date().getFullYear();
    const timestampYear = new Date(parsedTimestamp).getFullYear();

    return new Intl.DateTimeFormat(locale, {
      timeZone: timeZone,
      month: 'short',
      day: 'numeric',
      year: withCurrentYear ? 'numeric' : currentYear !== timestampYear ? 'numeric' : undefined,
      hour: '2-digit',
      minute: '2-digit',
      second: withSeconds ? '2-digit' : undefined,
      timeZoneName: withTZ ? 'short' : undefined,
    })
      .format(parsedTimestamp)
      .replace(/[^ -~]/g, ''); // Remove some garbage characters \u200e that IE11 seems to add.
  } else {
    return '';
  }
}

export function safeFormatDateTime(
  timeZone: string,
  timestamp?: FancyTime,
  withSeconds = true,
  withTZ = false,
  withCurrentYear = true,
  locale?: string
): string {
  const parsedTimestamp = parseDate(timestamp);
  if (timestamp && parsedTimestamp) {
    if (Intl.DateTimeFormat(locale).resolvedOptions().locale.startsWith('en')) {
      return formatDateTime(timeZone, timestamp, withSeconds, withTZ, withCurrentYear, locale);
    } else {
      // Return less friendly dates for locales other than English to avoid Sugar parsing issues like what happened in #2685
      return new Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: withSeconds ? '2-digit' : undefined,
        timeZoneName: withTZ ? 'short' : undefined,
      })
        .format(parsedTimestamp)
        .replace(/[^ -~]/g, ''); // Remove some garbage characters \u200e that IE11 seems to add.
    }
  } else {
    return '';
  }
}

// Returns true if the timezone is at the end of the formatted date.
// Allows us to style the timezone independently.
export function isTimeZoneAtEnd(locale?: string): boolean {
  const test = Intl.DateTimeFormat(locale, {
    timeZone: 'America/Chicago',
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }).format(new Date('2022-01-01T06:00:00.000Z'));

  return (
    test.endsWith(' CST') ||
    test.endsWith(' (CST)') ||
    test.endsWith(' [CST]') ||
    test.endsWith(' GMT-6') ||
    test.endsWith(' GMT−6') ||
    test.endsWith(' GMT-6‎') ||
    test.endsWith(' GMT -6') ||
    test.endsWith(' GMT −6') ||
    test.endsWith(' (GMT-6)') ||
    test.endsWith(' HNC') ||
    test.endsWith(' UTC-6') ||
    test.endsWith(' UTC−6') ||
    test.endsWith(' Гринуич-6')
  );
}

export function formatTimeZone(timeZone: string, timestamp: number, locale?: string): string {
  if (isTimeZoneAtEnd(locale)) {
    return Intl.DateTimeFormat(locale, {
      timeZone: timeZone,
      year: 'numeric',
      timeZoneName: 'short',
    })
      .format(timestamp)
      .replace(/[^ -~]/g, '') // Remove some garbage characters \u200e that IE11 seems to add.
      .substring(6); // Skip over numeric "year" and the space.
  } else {
    Logger.warn('Attempt to call formatTimeZone for an unsupported locale.');

    return '';
  }
}

function isDate(value?: string | number | Date): value is Date {
  if (Object.prototype.toString.call(value) === '[object Date]') {
    if (isNaN((value as Date).getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function parseDate(value?: string | number | Date): number | undefined {
  // Detect `now` regardless of language.
  let actualValue = value;
  if (value?.toLocaleString() === 'now') {
    // Change to a timestamp because Sugar doesn't support "now" in other languages.
    actualValue = Date.now();
  }

  // Sugar will parse using the browser's timezone just like vanilla JS.
  let date = Sugar.Date.create(actualValue);

  if (!isDate(date) && actualValue) {
    // Try using JS.
    date = new Date(actualValue);
  }

  // if value is a numeric timestamp, just return the date.getTime()
  if (value && typeof value === 'number' && isDate(date)) {
    return date.getTime();
  }

  const strValue = String(value);

  // We don't support certain Sugar keywords like "from" and "after".  Too complicated.
  if (INVALID_DATE_REGEX.test(strValue)) {
    return undefined;
  }

  if (isDate(date)) {
    const userTimeZone = (moment as any).defaultZone?.name || defaultTimezone;

    const isRelative = RELATIVE_DAY_REGEX.test(strValue) && !SPECIFIC_DAY_REGEX.test(strValue);

    // Check if we need to adjust the day because of the browser TZ being on a different day than the specified TZ.
    if (isRelative) {
      // If "now" was specified we can make things easy.
      if (strValue.toLowerCase() === 'now') {
        return Date.now();
      }

      if (IN_QUANTITY_REGEX.test(strValue)) {
        // Value was something like "in 30 minutes".
        // So we can safely return the actual unix timestamp.
        return date.getTime();
      }

      // It's a relative day so check what the adjustment (if any) should be.
      let dayAdjustment = 0;
      const timeZoneNow = moment.tz(Date.now(), userTimeZone);
      const localNow = moment.tz(Date.now(), moment.tz.guess());

      if (timeZoneNow.dayOfYear() !== localNow.dayOfYear() || timeZoneNow.year() !== localNow.year()) {
        let timeZoneNowDayOfYear = timeZoneNow.dayOfYear();
        let localNowDayOfYear = localNow.dayOfYear();
        if (timeZoneNow.year() > localNow.year()) {
          for (let year = localNow.year(); year < timeZoneNow.year(); year += 1) {
            timeZoneNowDayOfYear += moment(`${year}-12-31`).dayOfYear();
          }
        } else if (localNow.year() > timeZoneNow.year()) {
          for (let year = timeZoneNow.year(); year < localNow.year(); year += 1) {
            localNowDayOfYear += moment(`${year}-12-31`).dayOfYear();
          }
        }

        dayAdjustment = timeZoneNowDayOfYear - localNowDayOfYear;
      }

      date.setDate(date.getDate() + dayAdjustment);

      if (HAS_TIME_REGEX.test(strValue)) {
        // Return a new date with the given time.
        return (
          moment
            .tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess()) // sugar is local timezone so always parse with local TZ.
            .clone()
            // Passing true will keep the local time (this seems to be undocumented, except here):
            // https://stackoverflow.com/questions/28593304/same-date-in-different-time-zone/28615654#28615654

            // Keeping the time untouched is what we want, since the customer entered an actual time.
            .tz(userTimeZone, true)
            .valueOf()
        );
      } else {
        // Return a new date in customer's timezone without a time
        // Change to "start of day"
        return (
          moment
            .tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess()) // sugar is local timezone so always parse with local TZ.
            .clone()
            // Passing true will keep the local time (this seems to be undocumented, except here):
            // https://stackoverflow.com/questions/28593304/same-date-in-different-time-zone/28615654#28615654

            // Keeping the time untouched is what we want, since the customer entered an actual time.
            .tz(userTimeZone, true)
            .startOf('day')
            .valueOf()
        );
      }
    }

    if (TIMEZONE_REGEX.test(strValue)) {
      // A specific timezone was specified so no need to do anything.
      return date.getTime();
    } else if (HAS_TIME_REGEX.test(strValue) || ADJUST_BEGINNING_OR_END_TIME_REGEX.test(strValue)) {
      // A specific time was specified without a timezone, so adjust the offset.
      return (
        moment
          .tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess()) // sugar is local timezone so always parse with local TZ.
          .clone()
          // Passing true will keep the local time (this seems to be undocumented, except here):
          // https://stackoverflow.com/questions/28593304/same-date-in-different-time-zone/28615654#28615654

          // Keeping the time untouched is what we want, since the customer entered an actual time.
          .tz(userTimeZone, true)
          .valueOf()
      );
    } else if (!isRelative && !SKIP_TIME_ADJUSTMENT_REGEX.test(strValue) && typeof value !== 'number') {
      // If someone entered just a date, or some other relative specific date
      // the time will be parsed as midnight in the browser's timezone (versus the user's timezone),
      // so changing the time to midnight.
      return (
        moment
          .tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess()) // sugar is local timezone so always parse with local TZ.
          .clone()
          // Passing true will keep the local time (this seems to be undocumented, except here):
          // https://stackoverflow.com/questions/28593304/same-date-in-different-time-zone/28615654#28615654

          // Keeping the time untouched is what we want, since the customer entered an actual time.
          .tz(userTimeZone, true)
          .startOf('day')
          .valueOf()
      );
    } else {
      // Assume this is a relative or exactly specified date/time and return it as is.
      return date.getTime();
    }
  } else if (FANCY_TIME_REGEX.test(strValue)) {
    return nowOffsetToTimestamp(strValue);
  } else {
    return undefined;
  }
}

// Same as parseDate except `undefined-ish` and `now` is `now` instead of being turned into now's timestamp.
// Anything starting with `now-` is also returned as-is.
export function parseDateNowAsNow(value?: string | number | Date): number | string | undefined {
  if (!value) {
    return 'now';
  }

  return !String(value).toLocaleLowerCase().startsWith('now') ? parseDate(value) : String(value);
}

export function toIso(date?: Date | number | string): string | undefined {
  if (date) {
    return new Date(date === 'now' ? new Date() : date).toISOString();
  } else {
    return undefined;
  }
}

export function toIsoNowAsNow(date?: Date | number | string): string {
  const strDate = String(date).toLocaleLowerCase();
  if (strDate.startsWith('now')) {
    return strDate;
  }

  return toIso(date) || 'now';
}

export function parseRFC3339NanoDate(str: string): Date {
  // cSpell:disable-next-line
  return moment(str, 'YYYY-MM-DDTHH:mm:ss.SSSSSSSSSZ').toDate();
}

export function formatDateAsRFC3339Nano(date: Date): string {
  // cSpell:disable-next-line
  return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSSSSSSZ');
}

export function nsBigintToRFC3339Nano(nanosecondsSinceEpoch: bigint): string {
  // 🐉🐉🐉
  // Ensure that we are actually dealing with a bigint type, and not a number that represents a bigint
  // (which can technically be outside the EcmaScript MAX_SAFE_INTEGER, but our API does it anyway and it seems to work)
  // This is necessary because you can't do math with a mix of bigint and number types.
  const bigintNanosecondsSinceEpoch =
    typeof nanosecondsSinceEpoch === 'bigint' ? nanosecondsSinceEpoch : BigInt(nanosecondsSinceEpoch);

  // Convert BigInt to milliseconds for Date object, lose nanosecond precision temporarily
  const milliseconds = Number(bigintNanosecondsSinceEpoch / 1000000n);
  const nanosecondsPart = String(Number(bigintNanosecondsSinceEpoch % 1000000n)); // Extract nanoseconds

  // Create a Date object using milliseconds, append micro/nanoseconds
  const date = new Date(milliseconds);
  const start = date.toISOString().slice(0, -1);

  return `${start}${nanosecondsPart}Z`;
}

export function relativeShortTimeFromNow(date: Date): string {
  const { value, unit } = selectUnit(date);

  if (value < 0) {
    return '';
  }
  if (value < 1) {
    return 'Now';
  }

  switch (unit) {
    case 'second':
      return `${value}s`;

    case 'minute':
      return `${value} ${value === 1 ? 'min' : 'mins'}`;

    case 'hour':
      return `${value} ${value === 1 ? 'hour' : 'hours'}`;

    case 'day':
      return `${value} ${value === 1 ? 'day' : 'days'}`;

    case 'month':
      return `${value} ${value === 1 ? 'month' : 'months'}`;

    default:
      return `${value} ${value === 1 ? 'year' : 'years'}`;
  }
}

export function relativeShortTimeAgo(date: Date): string {
  let { value, unit } = selectUnit(date);

  // Invert value so we're working with positive numbers.
  // (all "values" in the past will be negative.
  value = -value;

  if (value < 0) {
    return '';
  }
  if (value < 1) {
    return 'Now';
  }

  let time = '';

  switch (unit) {
    case 'second':
      time = `${value}s`;
      break;

    case 'minute':
      time = `${value} ${value === 1 ? 'min' : 'mins'}`;
      break;

    case 'hour':
      time = `${value} ${value === 1 ? 'hour' : 'hours'}`;
      break;

    case 'day':
      time = `${value} ${value === 1 ? 'day' : 'days'}`;
      break;

    case 'month':
      time = `${value} ${value === 1 ? 'month' : 'months'}`;
      break;

    case 'year':
      time = `${value} ${value === 1 ? 'year' : 'years'}`;
      break;
  }

  return `${time} ago`;
}

export function relativeVeryShortTimeAgo(date: Date): string {
  let { value, unit } = selectUnit(date);

  // Invert value so we're working with positive numbers.
  // (all "values" in the past will be negative.
  value = -value;

  if (unit === 'second' && value < 1) {
    return 'now';
  }

  let time = '';

  switch (unit) {
    case 'second':
      time = `${value}s`;
      break;

    case 'minute':
      time = `${value}m`;
      break;

    case 'hour':
      time = `${value}h`;
      break;

    case 'day':
      time = `${value}d`;
      break;

    case 'month':
      time = `${value}mth`;
      break;

    case 'year':
      time = `${value}y`;
      break;
  }

  return `${time} ago`;
}

function isMomentSameDay(timeZone: string, momentDate: moment.Moment) {
  return momentDate.isSame(moment.tz(new Date(), timeZone), 'day');
}

export function isDateSameDay(timeZone: string, date: Date): boolean {
  const momentDate = moment.tz(date, timeZone);

  return isMomentSameDay(timeZone, momentDate);
}

export function relativeShortTimeIfToday(timeZone: string, date: Date): string {
  const momentDate = moment.tz(date, timeZone);
  const isSameDay = isMomentSameDay(timeZone, momentDate);

  if (isSameDay) {
    return relativeShortTimeAgo(date);
  } else {
    // Just return the time.
    return momentDate.format('h:mma');
  }
}

export const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

const timezones = getTimeZones();

export const SUPPORTED_TIMEZONES: TimeZone[] = timezones.filter((timezone) => {
  return browserSupportsTimezone(timezone.name);
});

// Add UTC
SUPPORTED_TIMEZONES.unshift({
  name: 'UTC',
  alternativeName: 'UTC',
  group: [],
  continentCode: '',
  continentName: '',
  countryName: '',
  countryCode: '',
  mainCities: [],
  rawOffsetInMinutes: 0,
  abbreviation: 'UTC',
  rawFormat: 'UTC',
  currentTimeOffsetInMinutes: 0,
  currentTimeFormat: 'UTC',
});

export enum CompareAgainst {
  '-1h' = '-1h',
  '-3h' = '-3h',
  '-6h' = '-6h',
  '-12h' = '-12h',
  '-1d' = '-1d',
  '-3d' = '-3d',
  '-7d' = '-7d',
  '-1w' = '-1w', // Deprecated. Use -7d instead for consistency with `7d`, ref FE-656
  '-2w' = '-2w',
  '-3w' = '-3w',
  '-30d' = '-30d',
  '-60d' = '-60d',
  '-90d' = '-90d',
}

export function isCompareAgainstKey(key: string): key is keyof typeof CompareAgainst {
  return key in CompareAgainst;
}

type CompareAgainstConversionType = { [key in CompareAgainst]: { amount: number; unit: DurationInputArg2 } };

type CompareAgainstMessageType = Record<CompareAgainst, { singular: string; plural: string }>;

export const compareAgainstMessages: CompareAgainstMessageType = Object.keys(CompareAgainst).reduce(
  abbreviatedTimeReducer,
  {} as CompareAgainstMessageType
);

const compareAgainstConversion: CompareAgainstConversionType = Object.keys(CompareAgainst).reduce((accum, key) => {
  // Convert key into variables that moment math can use.
  const unit = key.substring(key.length - 1);
  accum[key as CompareAgainst] = {
    amount: Number(key.substring(0, key.length - 1)),
    unit: unit as DurationInputArg2,
  };

  return accum;
}, {} as CompareAgainstConversionType);

export function compareAgainstTimestampToTimestamp(timestamp: FancyTime, compareAgainst: CompareAgainst): number {
  const conversion = compareAgainstConversion[compareAgainst];

  return moment(parseTimestamp(timestamp)).add(conversion.amount, conversion.unit).valueOf();
}

export const DEFAULT_REFRESH_RATE_SEC = 15;

export const REFRESH_RATES = [15, 60, 300];

export enum QuickRange {
  '5m' = '5m',
  '15m' = '15m',
  '30m' = '30m',
  '1h' = '1h',
  '3h' = '3h',
  '6h' = '6h',
  '1d' = '1d',
  '2d' = '2d',
  '7d' = '7d',
  '15d' = '15d',
  '30d' = '30d',
  '90d' = '90d',
}

export const QuickRangeSchema = z.nativeEnum(QuickRange);
export type QuickRangeKeys = keyof typeof QuickRange;

export function isQuickRangeKey(key: string): key is QuickRangeKeys {
  return key in QuickRange;
}

export const DEFAULT_QUICK_RANGE = QuickRange['30m'];

type QuickRangeConversionType = { [key in QuickRange]: { amount: number; unit: DurationInputArg2 } };

const fancyTimeSchema = z.string().or(z.number());
export type FancyTime = z.infer<typeof fancyTimeSchema>;

const timeRangeSchema = z.object({
  quickRange: QuickRangeSchema.optional(),
  timestampStart: fancyTimeSchema.optional(),
  timestampEnd: fancyTimeSchema.optional(),
});

export type TimeRange = z.infer<typeof timeRangeSchema>;

enum TimeUnit {
  's' = 's',
  'm' = 'm',
  'h' = 'h',
  'd' = 'd',
  'w' = 'w',
}

type TimeLengthMessageType = Record<TimeUnit, { singular: string; plural: string }>;

export const timeLengthMessages: TimeLengthMessageType = Object.keys(TimeUnit).reduce(
  abbreviatedTimeReducer,
  {} as TimeLengthMessageType
);

type QuickRangeMessageType = Record<QuickRange, { singular: string; plural: string }>;

export const quickRangeMessages: QuickRangeMessageType = Object.keys(QuickRange).reduce(
  abbreviatedTimeReducer,
  {} as QuickRangeMessageType
);

export const quickRangeConversion: QuickRangeConversionType = Object.keys(QuickRange).reduce((accum, key) => {
  // Convert key into variables that moment math can use.
  const unit = key.substring(key.length - 1);
  accum[key as QuickRange] = {
    amount: Number(key.substring(0, key.length - 1)),
    unit: unit as DurationInputArg2,
  };

  return accum;
}, {} as QuickRangeConversionType);

export function quickRangeToTimestamp(quickRange: QuickRange): number {
  const conversion = quickRangeConversion[quickRange];

  return moment().subtract(conversion.amount, conversion.unit).valueOf();
}

type QuickRangeSecondsType = { [key in QuickRange]: number };

export const quickRangeSeconds: QuickRangeSecondsType = Object.keys(QuickRange).reduce((accum, key) => {
  accum[key as QuickRange] = quickRangeToSeconds(QuickRange[key as QuickRange]);

  return accum;
}, {} as QuickRangeSecondsType);

type CompareAgainstSecondsType = { [key in CompareAgainst]: number };

export const compareAgainstSeconds: CompareAgainstSecondsType = Object.keys(CompareAgainst).reduce((accum, key) => {
  const conversion = compareAgainstConversion[CompareAgainst[key as CompareAgainst]];
  accum[key as CompareAgainst] = moment.duration(conversion.amount * -1, conversion.unit).asSeconds();

  return accum;
}, {} as CompareAgainstSecondsType);

function quickRangeToSeconds(quickRange: QuickRange): number {
  const conversion = quickRangeConversion[quickRange];

  return moment.duration(conversion.amount, conversion.unit).asSeconds();
}

export function parseTimestamp(timestamp?: FancyTime): number | undefined {
  if (timestamp) {
    if (typeof timestamp === 'number') {
      return timestamp;
    } else {
      const parsedTimestamp = parseDate(timestamp);

      if (parsedTimestamp) {
        return parsedTimestamp;
      }
    }
  }

  return undefined;
}

export function parseTimestampNotNow(timestamp?: FancyTime): number | undefined {
  if (timestamp === 'now') {
    return undefined;
  }

  return parseTimestamp(timestamp);
}

export function currentTimestampStart(quickRange?: QuickRange, timestampStart?: FancyTime): number | undefined {
  if (window.__mocks?.currentTimestampStart) {
    return window.__mocks.currentTimestampStart(quickRange, timestampStart);
  }

  if (quickRange) {
    return quickRangeToTimestamp(quickRange);
  } else if (timestampStart) {
    const parsedTimestamp = parseTimestamp(timestampStart);

    if (parsedTimestamp) {
      return parsedTimestamp;
    }
  }

  return undefined;
}

export function currentTimestampStartNowAsUndefined(
  quickRange?: QuickRange,
  timestampStart?: FancyTime
): number | undefined {
  if (timestampStart === 'now') {
    return undefined;
  } else {
    return currentTimestampStart(quickRange, timestampStart);
  }
}

export function currentTimestampEnd(quickRange?: QuickRange, timestampEnd?: FancyTime): number {
  if (window.__mocks?.currentTimestampEnd) {
    return window.__mocks.currentTimestampEnd(quickRange, timestampEnd);
  }

  if (quickRange) {
    // "End" is always now when using Quick Range.
    return Date.now();
  } else if (timestampEnd) {
    const parsedTimestamp = parseTimestamp(timestampEnd);

    if (parsedTimestamp) {
      return parsedTimestamp;
    }
  }

  return Date.now();
}

export function currentTimestampEndNowAsUndefined(
  quickRange?: QuickRange,
  timestampEnd?: FancyTime
): number | undefined {
  if (timestampEnd === 'now' || quickRange) {
    // "End" is always now when using Quick Range.
    return undefined;
  } else if (timestampEnd) {
    const parsedTimestamp = parseTimestamp(timestampEnd);

    if (parsedTimestamp) {
      return parsedTimestamp;
    }
  }

  return undefined;
}

function nowOffsetToTimestamp(nowOffset: string): number | undefined {
  if (FANCY_TIME_REGEX.test(nowOffset)) {
    const tokens = nowOffset.split('-');
    const offset = tokens[1];

    const unit = offset.substring(offset.length - 1) as DurationInputArg2;
    const amount = Number(offset.substring(0, offset.length - 1));

    return moment().subtract(amount, unit).valueOf();
  } else {
    return undefined;
  }
}

export function formattedRelative(
  value?: Date | number,
  options?: Intl.RelativeTimeFormatOptions,
  locale?: string
): string | undefined {
  if (!value) {
    return undefined;
  }

  const { value: selectedValue, unit } = selectUnit(value);

  const formattedRelativeTime = new Intl.RelativeTimeFormat(locale, options).format(selectedValue, unit);

  if (formattedRelativeTime === 'in 0 seconds') {
    return '0 seconds ago';
  } else if (formattedRelativeTime === 'in 0s') {
    return '0s';
  }

  return formattedRelativeTime;
}

export function isIsoDateWithMilliseconds(maybeIsoDateString: string) {
  const isoDateRegex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|([+-]\d{2}:\d{2})))|(\d{4}-\d{2}-\d{2})$/;
  const millisecondsRegex = /\.\d{3,}(Z|[+-]\d{2}:\d{2})$/;

  const isIsoDate = millisecondsRegex.test(maybeIsoDateString);
  const hasMilliseconds = isoDateRegex.test(maybeIsoDateString);

  return isIsoDate && hasMilliseconds;
}

export const FormattedDateShortOptions = {
  WithHourMinuteSecond: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
  },
  WithHourMinuteSecondAndMilliseconds: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
    // @ts-expect-error every browser supports this since 2021
    fractionalSecondDigits: 3,
  },
} satisfies Record<string, Intl.DateTimeFormatOptions>;

export function formattedDateShort({
  timeZone,
  value,
  extraOptions,
  locale,
  withoutYear,
}: {
  timeZone: string;
  value: Date;
  extraOptions?: Intl.DateTimeFormatOptions;
  locale?: string;
  withoutYear?: boolean;
}) {
  const yearFormat = new Intl.DateTimeFormat(locale, {
    timeZone: timeZone,
    year: 'numeric',
  });

  // Show year when not the current year in given timezone.
  if (withoutYear || yearFormat.format(value) === yearFormat.format(new Date())) {
    return new Intl.DateTimeFormat(locale, {
      timeZone: timeZone,
      month: 'short',
      day: '2-digit',
      ...extraOptions,
    }).format(value);
  } else {
    return new Intl.DateTimeFormat(locale, {
      timeZone: timeZone,
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      ...extraOptions,
    }).format(value);
  }
}

// This is a function because `Date.now()` continues to change.
export function isLicenseExpired(license?: { expiresAt?: string; tier: LicenseTier }) {
  return (
    !license ||
    (license.tier !== 'comped' && license.tier !== 'personal' && moment(license.expiresAt).isBefore(Date.now()))
  );
}

// This is a function because `Date.now()` continues to change.
export function isLicenseExpiringToday(license?: { expiresAt?: string; tier: LicenseTier }) {
  if (!license?.expiresAt) {
    return false;
  }

  const hoursDiff = moment(license.expiresAt).diff(Date.now(), 'hours');

  return license.tier !== 'comped' && hoursDiff >= 0 && hoursDiff <= 24;
}

export function isLicenseExpiringSoon(license?: { expiresAt?: string; tier: LicenseTier }) {
  if (!license?.expiresAt) {
    return false;
  }

  const hoursDiff = moment(license.expiresAt).diff(Date.now(), 'hours');

  return license.tier !== 'comped' && hoursDiff >= 0 && hoursDiff <= 24 * 7 * 4; // 4 weeks
}

export function daysUntilLicenseExpires(license?: { expiresAt?: string; tier: LicenseTier }) {
  if (!license?.expiresAt) {
    return 0;
  }

  const hoursDiff = moment(license.expiresAt).diff(Date.now(), 'hours');

  return Math.ceil(hoursDiff / 24);
}

export function daysRemainingInTrial(trialEndingBefore: Date | undefined) {
  return Math.max(0, moment(trialEndingBefore).diff(moment(), 'days'));
}

function padRight(str: string, length: number, char: string) {
  let ret = str;
  while (ret.length < length) {
    ret = `${ret}${char}`;
  }

  return ret;
}

export function nanoDatetimeToBigInt(nanoDatetimeStr: string): bigint {
  const parts = nanoDatetimeStr.split('.');

  const m = moment.tz(parts[0], 'UTC');
  const datetimeInNs = BigInt(m.valueOf()) * BigInt(1000000);

  let subSeconds = parts.length > 1 ? parts[1].slice(0, -1) : '0';

  subSeconds = padRight(subSeconds, 9, '0'); // Make this look like a ns string

  const subSecondsBigInt = BigInt(subSeconds);

  return datetimeInNs + subSecondsBigInt;
}

export const isOverTimeLimit = (timeLimitMs: number, startTimeMs: number) => {
  return Date.now() - startTimeMs > timeLimitMs;
};

export const shortTimeDifference = (
  startDate: string | undefined,
  endDate: string | undefined
): `${number}${string}` => {
  const start = new Date(startDate ?? 0);
  const end = endDate ? new Date(endDate) : new Date();

  const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000);
  const diffInSecondsAbs = Math.abs(diffInSeconds);

  const units = [
    { label: 'y', seconds: 60 * 60 * 24 * 365 },
    { label: 'mth', seconds: 60 * 60 * 24 * 30 }, // probably close enough
    { label: 'w', seconds: 60 * 60 * 24 * 7 },
    { label: 'd', seconds: 60 * 60 * 24 },
    { label: 'h', seconds: 60 * 60 },
    { label: 'm', seconds: 60 },
    { label: 's', seconds: 1 },
  ];

  for (const unit of units) {
    if (diffInSecondsAbs >= unit.seconds) {
      const value = diffInSeconds / unit.seconds;
      const roundedValue = Math.round(value);

      return `${roundedValue}${unit.label}`;
    }
  }

  return '0s'; // In case the dates are the same
};

/**
 * Ex. 01 Jan 2024, 17:00
 */
export const fmtLeadingDateYear24hr = (
  timeZone: string,
  timestamp: string,
  opts?: { second?: 'numeric' | '2-digit' | undefined }
) => {
  const parsedTimestamp = parseDate(timestamp);
  if (timestamp && parsedTimestamp) {
    const parts = new Intl.DateTimeFormat(undefined, {
      timeZone: timeZone,
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      second: undefined,
      timeZoneName: undefined,
      ...opts,
    })
      .formatToParts(parsedTimestamp)
      .reduce(
        (accum, part) => {
          accum[part.type] = part.value;

          return accum;
        },
        {} as Record<string, string>
      );

    // 01 Jan 2024, 17:00
    return `${parts.day} ${parts.month} ${parts.year}, ${parts.hour}:${parts.minute}`;
  } else {
    return '';
  }
};

/**
 * Ex. 01 Jan
 */
export const fmtDateMonth = (timeZone: string, timestamp: string) => {
  const parsedTimestamp = parseDate(timestamp);
  if (timestamp && parsedTimestamp) {
    const parts = new Intl.DateTimeFormat(undefined, {
      timeZone: timeZone,
      month: 'short',
      day: 'numeric',
      year: undefined,
      hour: undefined,
      minute: undefined,
      second: undefined,
      timeZoneName: undefined,
    })
      .formatToParts(parsedTimestamp)
      .reduce(
        (accum, part) => {
          accum[part.type] = part.value;

          return accum;
        },
        {} as Record<string, string>
      );

    return `${parts.day} ${parts.month}`;
  } else {
    return '';
  }
};

/**
 * Ex. 01 Jan 2024
 */
export const fmtLeadingDateYear = (timeZone: string, timestamp: string) => {
  const parsedTimestamp = parseDate(timestamp);
  if (timestamp && parsedTimestamp) {
    const parts = new Intl.DateTimeFormat(undefined, {
      timeZone: timeZone,
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: undefined,
      minute: undefined,
      second: undefined,
      timeZoneName: undefined,
    })
      .formatToParts(parsedTimestamp)
      .reduce(
        (accum, part) => {
          accum[part.type] = part.value;

          return accum;
        },
        {} as Record<string, string>
      );

    // 01 Jan 2024
    return `${parts.day} ${parts.month} ${parts.year}`;
  } else {
    return '';
  }
};
