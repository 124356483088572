import { orgStore } from '../../stores/OrgStore';

const AXIOMERS_ORGS = [
  'axiomers-ft83', // dev
  'axiomers-6qt8', // staging
  'axiomers-exxp', // prod
  'annotations-5i5r', // arne's demo org
];

// const AXIOM_PLAY_PROD = 'axiom-play-qf1k';

export const isAxiomer = () => Boolean(orgStore?.activeOrgId && AXIOMERS_ORGS.includes(orgStore.activeOrgId));
